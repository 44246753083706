import React from "react"
import { Col, Row } from 'antd'
import SvgPointer from "../../img/LiefletSvgPointers"
import Spinner from '../../components/PageComponents/Spinner'

// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'


interface Props{
  Point: any
}

const ModalLiefletComponent: React.FC<Props> = ({ Point }:Props ) => {


  let textShort = Point.text ? `${Point.text.substring(0, 160)}` : ' '
  textShort = textShort.slice(0, textShort.lastIndexOf("&"))

  const MarkerPoi = () => {

    const icon = new Icon({
      iconUrl:
        Point.category_id === 8 ? SvgPointer[0]
          : Point.category_id === 11 ? SvgPointer[1]
            : Point.category_id === 13 ? SvgPointer[2]
              : Point.category_id === 14 ? SvgPointer[3]
                : Point.category_id === 17 ? SvgPointer[4]
                  : Point.category_id === 18 ? SvgPointer[5] : SvgPointer[6],

      popupAnchor: [0, -20],
      iconSize: [40, 40]
    });



    return (
      <Marker
        key={Point.id}
        icon={icon}
        position={[parseFloat(Point.lat), parseFloat(Point.lon)]}
      >
        {/* <Popup >
          <div className={classes.Marker}>
            {
              Point.images.length !== 0 &&
              <img src={Point.images[0].url} alt={Point.title} />
            }
            <div>{Point.title}</div>
          </div>
        </Popup> */}
      </Marker>
    )
  }


  if (Point.lat === '') {
    return <Spinner /> 
  }

  return (
    <>
      <Row justify="center">
        <Col span={24}>

          <MapContainer
            key={Point.lat}
            center={[parseFloat(Point.lat), parseFloat(Point.lon)]}
            zoom={13}
            tap={false}
            style={{ height: '600px', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={true}
          >
        
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />
    
            <MarkerPoi />

            <LayersControl position="topright">

                <LayersControl.BaseLayer  name='Satellite'>
                  <LayerGroup >
                    <TileLayer maxZoom={19} url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
                  </LayerGroup>
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer checked name='Version 2'>
                  <LayerGroup >
                    <TileLayer maxZoom={19} url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
                  </LayerGroup>
                </LayersControl.BaseLayer>

            </LayersControl>

          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default ModalLiefletComponent