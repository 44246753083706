import React from "react"
import { Row, Col  } from 'antd'
import classes from './styles.module.css'
import { PartnersData } from './PartnersData'

const ProjectPage: React.FC = () => {

  return (
    <>
      <div className={ classes.backgroundImage }>
        <div className={classes.container}>
          <div className={classes.title}>Partners</div>
        </div>
        <div className={classes.container}>
          
            {PartnersData.map(data => {
              return (

                <Row justify="center" key={ data.id }>
                  <Col xs={24} sm={24} md={6} lg={6} >
                    <div className={classes.containerHeight}>
                      <div className={ data.website !== 'www.mobilnostbitola.org.mk' ? classes.imageLogo : classes.imageLogo2}><img src={data.imageLogo} alt={data.titleH1} /></div>
                    </div>

                    <div className={classes.website}>
                      <img src='../../img/website.png' alt='Website 1' />
                      <br /><span>Website</span>
                      <br /><span><div dangerouslySetInnerHTML={{ __html: data.website }} ></div></span>
                    </div>
                    <div className={classes.website}>
                      <img src='../../img/phone.png' alt='Phone 1' />
                      <br /><span>Phone</span>
                      <br /><span>{data.phone}</span>
                    </div>
                    <div className={classes.website}>
                      <img src='../../img/email.png' alt='Email 1' />
                      <br /><span>Email</span>
                      <br /><span>{data.email}</span>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={18} lg={18}>
                    <div className={classes.containerHeight}>
                      <div className={classes.titleH1}>{data.titleH1}</div>
                    </div>
                    <div className={classes.text} dangerouslySetInnerHTML={{ __html: data.text }} ></div>
                  </Col>

                  <hr className={classes.containerLine}/>
                </Row>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default ProjectPage