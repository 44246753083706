import React, { useState, useEffect } from "react"
import { Row, Col } from 'antd'

import SearchSvg from '../../img/svg_pointers/SearchSvg'
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import logo from "../../img/logo.png"
import logoTop from "../../img/logoTop.png"
import Navbar from './Navbar'
import SearchClickComponent from "../Search/SearchClickComponent"

import ModalLoginForm from "../Login//ModalLoginForm"
import ModalRegisterForm from "../Login/ModalRegisterForm"
import BasketComponent from '../../components/Basket/Basket'

//    Redux
import type { RootState } from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { Login }  from '../../redux/reducer/login'


const Header = () => {

  const [Top, setTop] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const url = new URL(`${process.env.REACT_APP_URL_CMS}/oauth/authorize?response_type=code&client_id=itNlXJDZ1RFzcen0zLIyi5q780J5gwUx&client_secret=qPvAZqlVFYHTyKU1xIGjSxB3jUTMcLU7&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=dotsoft&embedded=true`);
  
  
  const ReduxLogin: any[] = useSelector((state: RootState) => state.Logged.Login)
  const dispatch = useDispatch()
 
  // console.log(ReduxLogin)
  
  url.searchParams.set('redirect_uri', `${process.env.REACT_APP_REDIRECT_URI}`);


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setTop(true);
      } else {
        setTop(false);
      }
    });
  }, []);



  const LoginSignUpComponent = () => {

    return (
      window.localStorage.getItem("access_token") === null
        ?
        <>
          <span>
            <a className={[classes.color, classes.login].join(' ')} href={url.href}>Log in</a>
            <ModalRegisterForm clickModal={(e) => setActiveIndex(1)} /> 
          </span>
        </>
        :
        <span style={{marginTop: '5px', display: 'block'}}>
          <Link className={[classes.color, classes.account].join(' ')} to='/account'>My Account</Link> | 
          <Link className={[classes.color, classes.account].join(' ')} to='/' onClick={() => [dispatch(Login([])), window.localStorage.removeItem("access_token")]}> Log out</Link>
        </span>                 
    )
  }


  return (
    <>
      <Row justify="center" >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }} style={{ borderBottom: '3px solid rgb(21, 150, 201)'}}>
          <div className={classes.div}>
            <Row justify="center">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Link to="/">
                  <img src={logoTop} alt="Logo Alter-trip"/>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                
                <div style={{ justifySelf: 'end' }}>
                  <span className={classes.Language}>
                      <div className={classes.right}>
                      <div style={{ justifySelf: 'end' }}>
                        
                        <LoginSignUpComponent />
                        <BasketComponent
                          // item={item}
                          // basketPoiImage={basketPoi?.imageThumb}
                          // basketPoiTitle={basketPoi?.title}
                        />
                      </div>
                    </div> 
                  </span>
                </div>

              </Col>

            </Row>
          </div>
        </Col>
      </Row>

      <div className={ !Top ? [classes.header, classes.flexContainer].join(" ") : [classes.header, classes.flexContainer, classes.BorderBottom].join(" ")  }>
    
        <div className={classes.containerBotton}>

          <div className={classes.app_logo}>
            <Link to="/">
              <img src={logo} alt="Logo Chania" />
            </Link>
          </div>
          
          <Navbar />

          <div>
            <span className={classes.searchButton} >
              <SearchClickComponent item={<SearchSvg width={38} height={38} />} />
            </span>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Header