import React from "react"
import { Row, Col, Spin } from 'antd'
import classes from './styles.module.css'


const Spinner: React.FC = () => {


  return (
    <Row justify="center" >
      <Col>
        <div className={classes.loading}> <Spin size="large" /><span> Loading ...</span></div>
      </Col>
    </Row>
  )
}

export default Spinner
