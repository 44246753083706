import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import classes from "./StayTunedforTripsBtm.module.css"
import { ShakeOutlined } from '@ant-design/icons'
import TwitterSvg from '../../../img/svg_pointers/TwitterSvg'
import FacebookSvg from '../../../img/svg_pointers/FacebookSvg'
import GoogleSvg from '../../../img/svg_pointers/GoogleSvg'
import LinkedInSvg from '../../../img/svg_pointers/LinkedInSvg'
import { FilePdfOutlined } from '@ant-design/icons'

function StayTunedforTripsBtm() {


  return (
    <>
      <div className={classes.containerBig}>
        <div className={classes.container}>

          <Row justify="center" >
            <Col xs={24} sm={24} md={12} lg={4}>
              <div className={classes.div1}>
                <img className={classes.logoBtm} src='../img/logoFooter.png' />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className={classes.div1}>
                <div className={classes.header}>
                  <Link
                    to="/project"
                    state={{ page: 'project' }}
                    className={classes.linkA}>
                    THE PROJECT
                  </Link>
                </div>
            
                {/* <span className={classes.link}>
                  <p><Link to='' style={{ color: 'white' }}>TRAVELLING FOR PEOPLE WITH DISABILITIES</Link></p>
                  <p><Link to='' style={{ color: 'white' }}>ROUTES IN GREECE</Link></p>
                  <p><Link to='' style={{ color: 'white' }}>ABOUT US</Link></p>
                </span> */}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className={classes.div1}>
                <div className={classes.header}>
                  <Link
                    to="/partners"
                    state={{ page: 'project' }}
                    className={classes.linkA}>
                    PARTNERS
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className={classes.div1}>
                <div className={classes.header}>
                  <a href='http://altertrip.eu/' target='_blank' className={classes.linkA} >PROJECT WEBSITE</a>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <div className={classes.div1}>
                <div className={classes.header}>
                  <a href='./img/gis_altertrip_manual_portal.pdf' className={classes.linkA} target="_blank"  >ONLINE HELP <FilePdfOutlined /></a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>


      <div className={classes.containerBig2}>
        <Row justify="center" >
          <Col xs={24} sm={24} md={12} lg={14}>
            <div className={classes.div11}>
            {/* <TwitterSvg width={58} height={58} />
            <FacebookSvg width={58} height={58} />
            <GoogleSvg width={58} height={58} />
            <LinkedInSvg width={58} height={58} /> */}
             
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={10}>
            <div className={classes.div12}>
              <img className={classes.logoBtm2} src='../img/logoFooterBtm.png' />
            </div>
          </Col>
        </Row>
      </div>

      

      <div className={classes.containerBig3}>
        <div className={classes.container}>

          <Row justify="center" >
            <Col xs={24} sm={24} md={24} lg={22}>
              <div className={classes.developed}>
                <p>
                  The project is co-funded by the European Union and by National resources of the participating countries within the Interreg IPA CBC
                  Programme "Greece-Republic of North Macedonia" 2014-2020.
                </p>
                <p>
                  This webpage has been produced with the financial assistance of the European Union. The contents of the webpage are sole responsibility
                  of Altertrip and can in no way be taken to reflect the views of the European Union, the participating countries, the Managing
                  Authority and the Joint Secretariat.
                </p>
                <br/><br/>
              </div>
            </Col>
          </Row>



          <Row justify="center" >
            <Col xs={24} sm={24} md={24} lg={{ span: 22}}>
              <div className={classes.developed}> Developed by <a href='https://www.dotsoft.gr/' target="_blank">DOTSOFT SA</a></div>
            </Col>
          </Row>
        </div>
      </div>

      <Row justify="center" >
        <Col xs={24} sm={24} md={24} lg={24}>
          <img className={classes.logoBtmBtm} src='../img/logoFooterBtmBtm.jpg' />
        </Col>
      </Row>
    </>
  )
}

export default StayTunedforTripsBtm