import React from "react"
import Props from './svgProps'
    
const HearingSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_2970" data-name="Path 2970" d="M44.586,10.536l-8.249,8.593a10.627,10.627,0,0,0-1.449-4.79,10.986,10.986,0,0,0-2.948-3.054L39.025,4.8A24.472,24.472,0,0,0,0,24.474C0,28.841,2.556,34.3,6.014,39.7L16.558,28.049l7,4.76L10.77,46.364h-.048A166.448,166.448,0,0,0,24.473,62S48.947,37.99,48.947,24.474a24.351,24.351,0,0,0-4.361-13.938m-27.063,9.83h-2.57s1.431-7.794,8.778-7.794c7.506,0,8.434,8.107,8.434,8.107H29.6s-.786-5.973-5.959-5.952c-4.734.02-6.114,5.639-6.114,5.639m12.51,10.767c-.456,1.062-.544,1.428-.56,4.15-.02,3.379-1.147,4.714-3.235,5.657-1.455.657-4.257-.276-4.952-.276-.035-1.023-.035-1.023.026-3.057a6.376,6.376,0,0,0,3.632.4,2.777,2.777,0,0,0,1.936-3.4,8.07,8.07,0,0,1,2.054-6.467c2.6-3.013,3.941-5.329,4.086-7.756a9.068,9.068,0,0,0-8.981-8.828A9.412,9.412,0,0,0,15.2,16.036a8.5,8.5,0,0,0-1.47,4.33l-2.3,0a10.992,10.992,0,0,1,4.73-8.819,12.571,12.571,0,0,1,8.193-2.137,11.469,11.469,0,0,1,8.606,4.182,10.379,10.379,0,0,1,2.467,6.9c-.033,4.594-4.27,8.024-5.393,10.637" fill="#412879"/>
          <path id="Path_2971" data-name="Path 2971" d="M24.353,9.411a12.567,12.567,0,0,0-8.192,2.138,10.991,10.991,0,0,0-4.731,8.819l2.3,0a8.5,8.5,0,0,1,1.471-4.33,9.409,9.409,0,0,1,8.836-4.479,9.067,9.067,0,0,1,8.981,8.828c-.145,2.426-1.484,4.743-4.086,7.755a8.07,8.07,0,0,0-2.053,6.467A2.78,2.78,0,0,1,24.944,38a6.387,6.387,0,0,1-3.632-.4c-.06,2.033-.06,2.033-.026,3.057.7,0,3.5.932,4.952.275,2.088-.942,3.215-2.277,3.235-5.656.017-2.723.1-3.088.56-4.151,1.123-2.613,5.36-6.042,5.393-10.637a10.381,10.381,0,0,0-2.467-6.9,11.472,11.472,0,0,0-8.606-4.182" fill="#fff"/>
          <path id="Path_2972" data-name="Path 2972" d="M29.6,20.679h2.568s-.927-8.107-8.434-8.107c-7.346,0-8.778,7.794-8.778,7.794h2.571s1.38-5.619,6.114-5.639c5.172-.021,5.959,5.952,5.959,5.952" fill="#fff"/>
          <path id="Path_2973" data-name="Path 2973" d="M34.888,14.339a10.633,10.633,0,0,1,1.45,4.789l8.249-8.593A24.617,24.617,0,0,0,39.025,4.8l-7.084,6.487a10.984,10.984,0,0,1,2.947,3.055" fill="#fff"/>
          <path id="Path_2974" data-name="Path 2974" d="M23.559,32.809l-7-4.76L6.015,39.7c1.451,2.264,3.06,4.514,4.708,6.667h.047Z" fill="#fff"/>
        </g>
      </svg>
    )
}

export default HearingSvg  