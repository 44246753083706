import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { useSelector } from 'react-redux'


interface GsisCheckedArray{ value: number }
interface GsisCheckedState{ GsisChecked: GsisCheckedArray[] }
const initialState: GsisCheckedState = { GsisChecked: [] }

export const gsisCheckedSlice = createSlice({
  name: 'gsisChecked',
  initialState,
  reducers: {
    AddToGsisChecked: (state, action) => {
      state.GsisChecked.push(action.payload)
    },

    deleteFromGsisChecked: (state, action) => {
      // state.Basket = state.Basket.findIndex((item) => item.id === action.payload, 1)
      state.GsisChecked.splice(state.GsisChecked.findIndex((arrow: any) => arrow.id === action.payload), 1);
    },

    deleteAllFromGsisChecked: () => initialState,

    changeGsisChecked: (state, action) => {
      state.GsisChecked=(action.payload)
    }

  },
})

export const { AddToGsisChecked, deleteFromGsisChecked, deleteAllFromGsisChecked, changeGsisChecked } = gsisCheckedSlice.actions
export default gsisCheckedSlice.reducer 