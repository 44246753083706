
export const ThreeDataEn = [{
  id: 1,
  link: '/rights_and_legislation/rights_of_persons_with_disabilities',
  title: 'RIGHTS OF PERSONS WITH DISABILITIES',
  text: `Particular mention should be made of the Convention on the Rights of Persons with Disabilities, signed in New York on March 30, 2007,
  and the Optional Protocol to the Convention on the Rights of Persons with Disabilities. signed in New York on 27 September 2010,
  instruments which were ratified by Act 4074/2012 (Official Gazette issue no. 88 A')`
},
{
  id: 2,
  link: '/rights_and_legislation/protection_of_the_elderly',
  title: 'PROTECTION OF THE ELDERLY',
  text: `Human rights apply to all, regardless of age. Desptite an aging population, the vulnerability of the elderly
  and despite references to elderly in other international conventions, there is no interntional convention exclusively focusing on the
  elderly. The rights of the elderly are protected only under the general term "human rights"
  `
},
// {
//   id: 3,
//   link: '/rights_and_legislation/protection_of_the_elderly',
//   title: 'PROTECTION OF THE ELDERLY',
//   text: `Human rights apply to all, regardless of age. Desptite an aging population, the vulnerability of the elderly
//   and despite references to elderly in other international conventions, there is no interntional convention exclusively focusing on the
//   elderly. The rights of the elderly are protected only under the general term "human rights"
//   `  
//   },
  // {
  //   id: 4,
  //   link: '/rights_and_legislation/protection_of_the_elderly',
  //   title: 'PROTECTION OF THE ELDERLY',
  //   text: `Human rights apply to all, regardless of age. Desptite an aging population, the vulnerability of the elderly
  //   and despite references to elderly in other international conventions, there is no interntional convention exclusively focusing on the
  //   elderly. The rights of the elderly are protected only under the general term "human rights"
  //   `  
  // }
]



export const ThreeDataGr = [{
  id: 1,
  link: '',
  title: 'ΔΙΚΑΙΩΜΑΤΑ ΑΤΟΜΩΝ ΜΕ ΑΝΑΠΗΡΙΑ',
  text: `Ιδιαίτερη αναφορά πρέπει να γίνει στη Σύμβαση για τα Δικαιώματα των Ατόμων με Αναπηρία, που υπογράφηκε στη Νέα Υόρκη στις 30 Μαρτίου 2007,
  και το Προαιρετικό Πρωτόκολλο της Σύμβασης για τα Δικαιώματα των Ατόμων με Αναπηρία. υπογράφηκε στη Νέα Υόρκη στις 27 Σεπτεμβρίου 2010,
  πράξεις που κυρώθηκαν με τον Ν. 4074/2012 (ΦΕΚ αρ. 88 Α')`
},
{
  id: 2,
  link: '',
  title: 'ΠΡΟΣΤΑΣΙΑ ΗΛΙΚΙΩΜΕΝΩΝ',
  text: `Τα ανθρώπινα δικαιώματα ισχύουν για όλους, ανεξαρτήτως ηλικίας. Παρά τη γήρανση του πληθυσμού, την ευπάθεια των ηλικιωμένων
  και παρά τις αναφορές σε ηλικιωμένους σε άλλες διεθνείς συμβάσεις, δεν υπάρχει διεθνής σύμβαση που να επικεντρώνεται αποκλειστικά στην
  ηλικιωμένος. Τα δικαιώματα των ηλικιωμένων προστατεύονται μόνο με τον γενικό όρο «ανθρώπινα δικαιώματα»
  `
},
{
  id: 3,
  link: '',
  title: 'PROTECTION OF THE ELDERLY',
  text: `Human rights apply to all, regardless of age. Desptite an aging population, the vulnerability of the elderly
  and despite references to elderly in other international conventions, there is no interntional convention exclusively focusing on the
  elderly. The rights of the elderly are protected only under the general term "human rights"
  `  
}]


