import React from 'react'
import { Row, Col, Button } from 'antd'
import classes from "./css/Plan.module.css"
import { Link } from 'react-router-dom'


const Plan: React.FC = () => {

  return (
    <>
      <div className={classes.containerBig}>
        <div className={classes.container}>
          <Row justify="center">
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={classes.sliderContainer}>
                <img className={classes.image} alt="Chania image 1" src="./img/plan.png" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={classes.sliderContainerEnd}>   
                <Link to="/gsis" state={{ page: 'gsis' }}>

                  <Button
                    size={'large'}
                    className={classes.StartHere}
                  >START HERE</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className={classes.container2}>  
        <img src='./img/home_map.png' alt='Gsis Map'/>
      </div>

    </>
  )
}

export default Plan