import React from "react"
import { Row, Col  } from 'antd'
import classes from './styles.module.css'
import { ProjectData } from './ProjectData'
 
const ProjectPage: React.FC = () => {

  return (
    <>
      <div className={ classes.backgroundImage }>
        <div className={classes.container}>
          <div className={classes.title}>{ ProjectData.title}</div>
        </div>
        <div className={classes.container2}>
          <Row justify="center" >
            <Col xs={24} sm={24} md={24} lg={24}>

              <div className={classes.TitleText}>{ProjectData.TitleText}</div>
              <div className={classes.subTitle}>{ProjectData.subTitle}</div>
              <div className={classes.subTitleText} dangerouslySetInnerHTML={{ __html: ProjectData.subTitleText }} ></div>
           
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ProjectPage