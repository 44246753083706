import React from "react"
import Props from './svgProps'
    
const SitesMonumentsSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_3011" data-name="Path 3011" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#a67c52"/>
          <path id="Path_3012" data-name="Path 3012" d="M31.974,19.121V37.965a1.875,1.875,0,0,1-3.75,0V19.215H26.349v18.75a1.875,1.875,0,0,1-3.75,0V19.215H20.724v18.75a1.875,1.875,0,0,1-3.75,0V19.121a4.687,4.687,0,0,1,.938-9.281H31.036a4.687,4.687,0,0,1,.938,9.281Z" fill="#fff"/>
        </g>
      </svg>
    )
}

export default SitesMonumentsSvg  