import React, {useState, useEffect} from "react"
import { Button, Modal, Form, Input, notification, Divider, Col, Checkbox, message } from 'antd'
import classes from './styles.module.css'
import { useUserRegister } from './useService'
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons'
import User from "../../img/svg_pointers/UserSvg"

interface Props{
    clickModal: (one : number) => void,
}

const ModalRegisterForm: React.FC<Props> = ({clickModal}) => {

    
    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification()
    const [messageApi, contextHolderMes] = message.useMessage();
    const [email, setEmail] = useState<string>('') 
    const [password, setPassword] = useState<string>('')
    const [password2, setPassword2] = useState<string>('')
    const [checkBox, setCheckBox] = useState<boolean>(false)

    const error = (text:string) => {
        messageApi.open({
            type: 'error',
            content: text,
        });
    };

    // const openNotification = (title: string, text:string) => {
    //     api.info({
    //     message: title,
    //     description: text,
    //     placement: 'top',
    //     });
    // }

    const { Message, refetch } = useUserRegister(email, password)


    const closeModal = () => {
        Modal.destroyAll()
        clickModal(1)
      //  window.location.replace(`${process.env.REACT_APP_URL_CMS}/user/login?destination=/oauth/authorize&client_id=itNlXJDZ1RFzcen0zLIyi5q780J5gwUx&client_secret=qPvAZqlVFYHTyKU1xIGjSxB3jUTMcLU7&embedded=true&redirect_uri=${process.env.REACT_APP_URL_CMS}/gsis%26response_type=Dcode%26scope=Ddotsoft`);
                    
        window.location.replace(`${process.env.REACT_APP_URL_CMS}/user/login?destination=/oauth/authorize%3Fclient_id%3DitNlXJDZ1RFzcen0zLIyi5q780J5gwUx%26client_secret%3DqPvAZqlVFYHTyKU1xIGjSxB3jUTMcLU7%26embedded%3Dtrue%26redirect_uri=${process.env.REACT_APP_URL_CMS}/gsis%26response_type%3Dcode%26scope%3Ddotsoft`);


    }

    const showModal = () => {
        Modal.info({
            centered: true,
            closable: true,
            footer: null,
            title: 'Create Account',
            width: 520,
            content: (
                <>
                    <br />
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        wrapperCol={{ span: 22 }}
                        form={form}
                        onFinish={onFinish}
                        style={{ maxWidth: 500 }}
                    >
                        
                        <Form.Item label="Email" name="email" hasFeedback rules={[{ type:'email', required: true, message: 'Enter your Email' }]}>
                            <Input prefix={<MailOutlined  className={classes.colorGrey} />} placeholder={'Enter your Email' || undefined } />
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        <Form.Item label='Password' name="password" hasFeedback rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input.Password prefix={<LockOutlined className={classes.colorGrey} />} placeholder={'Please input your password' || undefined } />
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        <Form.Item label='Confirm Password' name="password2" hasFeedback rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input.Password prefix={<LockOutlined className={classes.colorGrey} />} placeholder={'Please input your password' || undefined }/>
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 22 }}>      
                            <div className={classes.checkBox}>
                                <Checkbox></Checkbox>&nbsp;&nbsp;
                                I agree with  <a href= '/' className={classes.underline}>Terms of use</a> and <a href='/' className={classes.underline} >Privacy Policy</a>
                            </div>
                        </Form.Item> */}

                        <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                            <Button htmlType="submit" className={classes.signUpBtn}>Sign Up</Button>
                            <Button htmlType="button" className={classes.signUpBtn} onClick={onReset}>Clean</Button>
                            {/* <Button type="link" htmlType="button" onClick={onFill} >Συμπληρώστε τη φόρμα Sergios...</Button> */}
                        </Form.Item>

                        <div className={classes.textBold} >
                            You are already registered <span className={classes.textBoldSignIn} onClick={()=> closeModal()}> Log in to your account</span> 
                        </div>
                    </Form>
                </>
            ),
            onOk() { },
        });
    };



    useEffect(() => {
        if (email !== '' && password !== '')
            refetch()
    }, [email, password])

    


    // useEffect(() => {
    //     if (Message)
    //         openNotification(t('Δημιουργία Λογαριασμού'), t('Ελέγξτε το email σας, για να ολοκληρώσετε την εγγραφή σας! Ελέγξτε τον φάκελο SPAM, εάν δεν μπορείτε να τον βρείτε στα εισερχόμενά σας!'))
    // }, [Message])

    useEffect(() => {
        if (Message == 200)
          messageApi.open({ type: 'success', content: 'Registered successfully' });   
       
    }, [Message])


    const onFinish = (values: any) => {

        // if (values.remember == true) {
            if (values.password === values.password2) { 
                setEmail(values.email)
                setPassword(values.password)
                setPassword2(values.password2)
                setCheckBox(values.remember)
                form.resetFields()
                Modal.destroyAll()
            }
            else 
                error('The passwords do not match')
        // }
        // else
        //     error('Please accept the Terms of Use and Privacy Policy') 
    }


    const onReset = () => {
        form.resetFields();
    }

    const onFill = () => {
        form.setFieldsValue({ email: 'ser11@mail.ru', password: '12345678', password2: '12345678' });
    }


    return (
        <>
            {contextHolder}
            {contextHolderMes}
         
            <span
                onClick={showModal}
                className={[classes.color, classes.login].join(' ')}>
                Sign Up
            </span>  <User width={38} height={38} />
        </>
    )
}
export default ModalRegisterForm
