
import React, { useState, useEffect, useLayoutEffect, useCallback } from "react"
import { Row, Col, Button, Drawer, Rate, Carousel, notification, Modal, Switch, Popconfirm, message } from 'antd'
import classes from './styles.module.css'
import css from './wizzard.module.css'


import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'


import { usePoints, useAddRemoveFavoriteHook } from '../../hooks/useMap'
import { useRefreshTokenHook } from '../../components/Login/useService'
import { useNavigate } from "react-router-dom"

import Spinner from '../../components/PageComponents/Spinner'
import utils from '../../utils'
import HelmetComponent from '../../components/Helmet'

import HeartSvg from '../../img/svg/heart'

import LiefletComponent from './Lieflet'
import WizzardComponent from './Wizzard'


import {
  CloseCircleOutlined,
  BankOutlined,
  ClockCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  HeartOutlined,
  ExclamationOutlined,
  DownloadOutlined
} from '@ant-design/icons'

import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { PointsProps } from "../../services/props"
import { settingsCarouselMap, settingsCarouselModal } from '../../components/ArrowsForCarousel/Categories'
import { useToken } from '../../components/Login/useService'
import ReactPlayer from 'react-player'

import useSpeech from "use-speech18"
import ModalComponent from '../Gsis/Modal'

// Redux
import { useDispatch } from 'react-redux'
import { AddToBasket }  from '../../redux/reducer/basket'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'


const GsisPlanned: React.FC = () => {

  const [messageApi, errorMessage] = message.useMessage()
  const [zoom, setZoom] = useState<number>(5)
  // const navigate = useNavigate()
  // const dispatch = useDispatch()


  const basketArray: any[] = useSelector((state: RootState) => state.Basket.Basket)
  const Gsis: any[] = useSelector((state: RootState) => state.GsisPlanned.GsisPlanned)

  // console.log(Gsis)

  const { isLoading, isFetching, Points, Categories, CategoryArray, refetchPoints } = usePoints()

  const [items, setItems] = useState<PointsProps[]>([])
  // const [gsisModalItems, setGsisModalItems] = useState<PointsProps[]>([])

  const [gsisItems, setGsisItems] = useState<PointsProps[]>([])

  // useEffect(() => {
  //   setGsisModalItems(Points)
  //   // console.log('fsd3')
  // }, [Points])


  useEffect(() => {
    
    // console.log('basketArray', basketArray)
    setGsisItems(Gsis)
  }, [Gsis])



  const url = new URL(`${process.env.REACT_APP_URL_CMS}/oauth/authorize?response_type=code&client_id=itNlXJDZ1RFzcen0zLIyi5q780J5gwUx&client_secret=qPvAZqlVFYHTyKU1xIGjSxB3jUTMcLU7&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=dotsoft&embedded=true`);
  
  const [modal, contextHolderModal] = Modal.useModal()

  const [api, contextHolder] = notification.useNotification()

  const [ isLogged, setIsLogged] = useState<boolean>(false)






  // window.localStorage.getItem("access_token") === null ? console.log('Not Logged', Points) : console.log('Logged', Points)
  // console.log('Points', Points)
  // console.log('PointsLogged', Points)
  
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [hideLine, setHideLine] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);



  const [item, setItem] = useState<PointsProps>()
  const [idPoint, setIdPoint] = useState<number>(0)
  const [idModalPoint, setIdModalPoint] = useState<number>(0)

  
  const [toLat, setTolat] = useState<any>('')
  const [toLon, setTolon] = useState<any>('' || undefined)
  
  // const [checkedItems, setCheckedItems] = useState<CheckboxValueType[]>(['1', '2', '3'])
  const [checkedCategoriesItems, setCheckedCategoriesItems] = useState<CheckboxValueType[]>(['Museums', 'Various', 'Religious sites', 'Hotels', 'Restaurants', 'Sites & Monuments', 'Culture & Arts'])
  const [isDisabledAddToBasketButton, setIsDisabledAddToBasketButton] = useState<boolean>(false)


    
  useEffect(() => {
    setItems(basketArray)
  }, [basketArray])

  useEffect(() => {
    refetchPoints()
  }, [isLogged])

  // useLayoutEffect(() => {
  //   let result = utils.MapFilter(Points, checkedItems, checkedCategoriesItems)
  //   setItems(result)
  // }, [checkedItems, checkedCategoriesItems])

  
  // const showDrawer = () => { setOpen(true); setShowFilter(false) }
  // const onClose = () => { setOpen(false); setTimeout(() => { setShowFilter(true) }, 50)  }
  const onClose2 = () => { setOpen2(false); setTimeout(() => { setShowFilter(true) }, 50); setZoom(6); }



  const PointId = (id: number) => {
    setIdPoint(id)
  }

  const PointModalId = (id: number) => {
    setIdModalPoint(id)
  }


  

  useEffect(() => {
   
      if (idPoint !== 0) {

        // refetchPoints()
        const item = basketArray.filter((item: any) => item.id === idPoint)
        // console.log(Points)
        // console.log(item)
        // console.log(id)
        setItem(item[0])
        setOpen2(true)
        setShowFilter(false)
        setZoom(16)
      }

  }, [idPoint])
  



  useEffect(() => {
   
    if (idModalPoint !== 0) {

      const item = Points.filter((item: any) => item.id === idModalPoint)
      setOpenModal(true)
      setItem(item[0])
    }

},[idModalPoint])

  
  
  
  
  
  
  //////////////////////////////////////////      Speech Voices     ///////////////////////////////////////////
  const { Speak, speak_utils } = useSpeech()
  const [isSpeech, setSpeech] = useState<boolean>(true)


  const textToSpeech = (title: string) => { isSpeech && Speak(title) }

  function getSelectedText() {
    isSpeech && Speak(window?.getSelection()?.toString() || '')
  }

  useEffect(()=> {
    // console.log("array of avalaible voices", speak_utils.Voices)
    speak_utils.setVoice(speak_utils.Voices[2].name)
    // console.log(speak_utils.selectedVoice, "currently selected voice")   
  }, [speak_utils.Voices])


  const onChangeSpeech = (checked: boolean) => {
    setSpeech(checked)
  };

 //////////////////////////////////////////      end Speech Voices     ///////////////////////////////////////////


  
  
  
  
 //////////////////////////////////////////      Open Message Window     ///////////////////////////////////////////

  const error = () => {
    
    setIsDisabledAddToBasketButton(true)

    messageApi.open({
      type: 'error',
      content: 'This location has already been added to your favorites',
      style: {
        marginTop: '20vh',
      },
    });

    setTimeout(() => {
      setIsDisabledAddToBasketButton(false)
    }, 2000);

  };
 //////////////////////////////////////////      End Message Window     ///////////////////////////////////////////


  const setTolatAndLon = (lat: any, lon: any) => {
    setTolat(lat)
    setTolon(lon)
  }


  if ("geolocation" in navigator) {
  } else {
      api.info({
        message: `Open Gps`,
        description:'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
  }










  const [codeParam, setCodeParam] = useState<any>(null)
  const [toggle, setToggle] = useState<boolean>(false)
  const [idAddRemoveFavorite, setIdAddRemoveFavorite] = useState<number>(0)
  const [basketPoi, setBasketPoi] = useState<PointsProps>()
  const { Token, refetch } = useToken( codeParam )
  const { refetchAddRemoveFavorite } = useAddRemoveFavoriteHook(idAddRemoveFavorite)
  const { refetchRefreshTokenHook } = useRefreshTokenHook()





  const addFavorite = (id: number) => {

    if (window.localStorage.getItem("access_token") !== null){

      if (id !== 0) {
        setToggle(!toggle)
        setIdAddRemoveFavorite(id)

        setTimeout(() => {
          refetchAddRemoveFavorite()
          refetchRefreshTokenHook()
        }, 1000);
        
        let objIndex = Points.findIndex((item => item.id == id))
        Points[objIndex].favorite = Points[objIndex].favorite === true ? false : true
      }

    } 

    else {
      console.log('Please Login or Register')
    }

  }





  // set content margin to be 20px
  const MARGIN = 20;


  function delay(time:any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 5000);
  };





  function downloadImage(data: any, filename = 'untitled.png') {
    const a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
}
   


  const createScreenshotPDF = () => {

    enterLoading(2)

    setHideLine(true)
    setTimeout(() => {
       setHideLine(false)
    }, 1000);

    const content = document.getElementById('content');
    const width = content ? content.offsetWidth : 10;
    const height = content ? content.offsetHeight : 10;


    if (content === null) return;
    // if (content !== null && content !== undefined) {
     
      delay(100)
        .then(() =>
         html2canvas(content!, {
          scrollX: 10, // scroll to X origin
          scrollY: 10, // scroll to Y origin
          scale: 5, // 5 is quality scale
          useCORS: true,
           removeContainer: false,
          allowTaint: true

          })
        .then(canvas => {
      
          // document.body.appendChild(canvas)
            // .replace("image/png", "image/octet-stream"); 
            // downloadImage(img, "screenshot.png");
            new jsPDF({
            orientation: 'p',
            unit: 'px', 
            format: [width+40, height],
            }).addImage(canvas.toDataURL("image/jpeg", 10), 'JPEG', MARGIN, MARGIN, width, height)
              .save('screenshot.pdf')
                 
          }
        )
      )
    // }
    
  }






  if (isLoading || isFetching || gsisItems == null) {
    return <Spinner /> 
  }


  return (
    <>
      {contextHolder}
      {contextHolderModal}
      {errorMessage}

      <HelmetComponent title='Alter Trip | Gsis' descrition='Alter Trip' />
      
      <Button
        type="primary"
        shape="round"
        onClick={createScreenshotPDF}
        icon={<DownloadOutlined />}
        size={'middle'}
        className={classes.DownloadPdf}
        loading={loadings[2]}
        >
        Download PDF
      </Button>
      <Switch
        onChange={onChangeSpeech}
        checkedChildren="Speech Enabled"
        unCheckedChildren="Speech Disabled"
        defaultChecked
        className={classes.btnSpeech} />
     

    <div id="content">
      <Row>
          <Col xs={24} sm={24} md={24} lg={24} >
            

            
          <LiefletComponent
            Points={items}
            IdPoint={(e: number) =>  PointId(e) }
            sendTextToSpeech={(e: string) => textToSpeech(e)}
            Tolat={toLat}
            Tolon={toLon}
            zoom={zoom}
            hideLine={hideLine}
          /> 

          <div style={{ opacity: '1' }}>
           

            <Drawer
              drawerStyle={{ background: '#0c4081', opacity: '1' }}
              closeIcon={<CloseCircleOutlined style={{color: 'white', fontSize: '23px'}} />}
              autoFocus={false}
              mask={false}
              title={
                <div>
                  <div className={classes.itemTitle}>{item?.title}</div>
                  
                    {window.localStorage.getItem("access_token") === null ?
                      
                      <div className={classes.heart}>
                        <Popconfirm
                          placement="topRight"
                          title={<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#05324c', lineHeight: '1.2' }}>You have to be {<a href={url.href}>logged in</a>} for adding a location as favorite.</div>}
                          icon={<ExclamationOutlined style={{ color: 'red', fontWeight: '600', fontSize: '26px' }} />}
                          okText={'Close'}
                          showCancel={false}
                        >
                          <div>
                            <HeartSvg width={36} height={36} fill={item?.favorite === false ? 'white' : 'red'} />
                          </div>
                        </Popconfirm>
                      </div>
                      : 
                      <div className={classes.heart}
                        onClick={() => addFavorite(item?.id || 0)}>
                          <HeartSvg width={36} height={36} fill={item?.favorite === false ? 'white' : 'red'} />
                      </div>
                    }
                 
                </div>
              }
              placement={'right'}
              onClose={onClose2}
              open={open2}
              getContainer={false}
              width={380}
            >
              <div className={classes.checkBoxesContainer}>

                {item?.images && item?.images?.length > 0 &&
    
                  <div style={{paddingBottom: '20px'}}>
                    <Carousel {...settingsCarouselMap}>
                      {
                        item?.images.map((image: any, index: number, row: any) => {
                          return (
                            <div key={index}>    
                              { image.description !== 'Video' ?
                                <div className={classes.helper} ><img src={image.url} alt={image.description}  /></div>
                                : <div className={css.videoSmall}><ReactPlayer controls={true} url={image.url} width={'300px'} height={'160px'} style={{marginLeft: '10px', marginTop: '12px'}} /></div>
                              }
                            </div>
                          )
                        })
                      }
                    </Carousel>
                  </div>
                }

                <div className={classes.itemData}>

                  <div className={classes.icon}><BankOutlined style={{fontSize: '24px'}} /> </div>
                  <div className={classes.type} onMouseUp={getSelectedText}>Category:<br/><span>{item?.category}</span></div>
               
                  {item?.schedule !== '\n ' &&
                    <>
                      <div className={classes.icon2}><ClockCircleOutlined style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type2} onMouseUp={getSelectedText}>Schedule:<br /> {item && <div style={{fontWeight: '700'}} dangerouslySetInnerHTML={{ __html: item?.schedule }} ></div> }</div>
                    </>
                  }
                  {item?.address &&
                    <>
                      <div className={classes.icon}><EnvironmentOutlined style={{fontSize: '24px'}} /> </div>
                      <div className={classes.typeAdress} onMouseUp={getSelectedText}>Address:<br /><span>{item?.address}</span></div>
                    </>
                  }
                  {item?.email &&
                    <>
                      <div className={classes.icon}><MailOutlined  style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Email:<br /><span>{item?.email}</span></div>
                    </>
                  }
                  {item?.telephone &&
                    <>
                      <div className={classes.icon}><PhoneOutlined  style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Telephone:<br /><span>{item?.telephone}</span></div>
                    </>
                  }
                  {item?.website &&
                    <>
                      <div className={classes.icon}><GlobalOutlined  style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Website:<br /><span><a href={ item?.website } target="_blank" className={classes.website}>{item?.website}</a></span></div>
                    </>
                  }

                  { (item?.facebook || item?.twitter || item?.instagram || item?.youtube) &&
                    <>
                      <div className={classes.icon}></div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Social Media<br />
                      {item?.facebook &&
                        <a href={ item?.facebook } target="_blank">
                          <span className={classes.social}><FacebookOutlined style={{ fontSize: '26px', paddingLeft: '0px' }} /></span>
                        </a>}
                      {item?.twitter &&
                        <a href={ item?.twitter } target="_blank">
                          <span className={classes.social}><TwitterOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                        </a>
                      }
                      {item?.instagram &&
                        <a href={ item?.instagram } target="_blank">
                          <span className={classes.social}><InstagramOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                        </a>
                      }
                      {item?.youtube &&
                        <a href={ item?.youtube } target="_blank">
                          <span className={classes.social}><YoutubeOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                        </a>
                      }
                     </div>
                    </>
                  }
                  <div className={classes.icon3}></div>
                  <div className={classes.type3}></div>
                  
                </div>

                <hr/>
                <div className={classes.itemAccessibilityText} onMouseUp ={getSelectedText}>Accessibility</div>

                <div className={classes.itemRate} ><Rate disabled value={item?.visual} />&nbsp;&nbsp;&nbsp;&nbsp;<span onMouseUp={getSelectedText}> Visual impairment</span></div>
                <div className={classes.itemRate} ><Rate disabled value={item?.hearing} />&nbsp;&nbsp;&nbsp;&nbsp;<span onMouseUp={getSelectedText}> Hearing impairment</span></div>
                <div className={classes.itemRate} ><Rate disabled value={item?.physical} />&nbsp;&nbsp;&nbsp;&nbsp;<span onMouseUp={getSelectedText}> Physical impairment</span></div>

              </div>
                
            </Drawer>

          </div>

        </Col>
      </Row>
        


      <WizzardComponent
        gsisItems={gsisItems}
        IdPoint={(e: number) => PointModalId(e)}/>
      </div>
      

      <div style={{display: 'none'}}>
        <ModalComponent
          item={item}
          openModalBoolean={openModal}
          setOpenModalBoolean={() => setOpenModal(false)}
          getSelectedText={ getSelectedText } />
      </div>

    </>
  )
}

export default GsisPlanned
