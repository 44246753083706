export const PartnersData = [
  {
    id: 1,
    imageLogo: '../../img/logoCompany_1.png',
    titleH1: 'BUSINESS & EXHIBITION RESEARCH AND DEVELOPMENT INSTITUTE (IEE):',
    website: `<a href='https://www.iee.org.gr' target="_blank" style="color:#616161;">https://www.iee.org.gr</a>`,
    phone: '+30 2310 291590',
    email: 'info@iee.org.gr',
    text: `
    <p>
    The Business & Exhibition Research and Development Institute is a non-profit organization whose main target is the scientific market analysis, specializing in 
    the exhibition and congress market and the relevant activities, as well as the extraction of useful conclusions to the benefit of the state, 
    the private sector, and the economy.</p> 
    
    <p>
    The Institute evaluates the economic effects of exhibition events on the local and regional economy, calculating the exhibition multiplier econometrically, 
    along with the effects of exhibitions on basic macroeconomic magnitudes such as the increase of income per capita, and the increase in the number of job 
    vacancies.</p> 
    
    <p>
    The Institute maintains and updates an elaborated database concerning exhibition centers and exhibition events held in Greece. It collects data 
    from all trade fairs and conducts sampling surveys in representative exhibitions (exhibition auditing), according to UFI rules, with a view to determining 
    their success and the potential of the sector they represent. </p>
    
    <p>
    The Business & Exhibition Research and Development Institute conducts studies for the State and other Stakeholders, with respect to the competitive sectors 
    of the national economy and according to the need for support of various business sectors (mostly represented in field exhibitions in Greece and abroad) 
    that can become competitive within an international environment. It organizes seminars and conferences on subjects related to exhibition activity and 
    multiple other business fields.</p>
    `
  },

  {
    id: 2,
    imageLogo: '../../img/logoCompany_2.png',
    titleH1: 'MINISTRY OF INTERIOR (SECTOR MACEDONIA-THRACE):',
    website: `<a href='https://www.mathra.gr' target="_blank" style="color:#616161;">https://www.mathra.gr</a>`,
    phone: '+30 2313 501100',
    email: 'info@mathra.gr',
    text: `
    <p>
    The Ministry of Interior (Sector Macedonia-Thrace) represents the governmental policy in Northern Greece. The Ministry’s task is the implementation of 
    strategies for regional development of Macedonia and Thrace, the protection and enhancement of the natural, architectural, and cultural heritage, and the 
    promotion of cooperation with other countries on environmental issues, infrastructure projects, cultural heritage, and tourism development.</p>
    
    <p>
    The Ministry is in Thessaloniki, the second largest city of Greece, and constitutes a well-structured Organization with a separate Department for managing 
    European Programs. It also has an active role in the implementation of the Development Policy Law for the region of Northern Greece, the industrial control 
    of the market, the issuance of licenses of the listed for protection buildings and preserved traditional areas. It has adopted political extroversion and 
    actively participates in international programs in order to promote the comparative advantages of the wider area, to promote cooperation with European and 
    international organizations, to develop cooperation regions abroad, to create relationships with foreign counterparts in common interest areas, and finally 
    to exploit European funds for the benefit of the citizens of Northern Greece.</p>
    `
  },
  {
    id: 3,
    imageLogo: '../../img/logoCompany_3.png',
    titleH1: 'ASSOCIATION OF PERSONS WITH PHYSICAL DISABILITIES OF BITOLA, DEMIR HISAR AND RESEN, MOBILNOST BITOLA:',
    website: `<a href='https://www.mobilnostbitola.org.mk' target="_blank" style="color:#616161;">www.mobilnostbitola.org.mk</a>`,
    phone: '+389 (0) 47 232 733',
    email: 'nikojankov@hotmail.com',
    text: `
    <p>
    The Association of Persons with Physical Disabilities of Bitola, Demir Hisar and Resen – MOBILNOST Bitola is a non-governmental, non-profit and non-partisan 
    organization of persons with physical disabilities located in Bitola, Republic of North Macedonia, with about 1100 registered members to date. Founded in 
    1987, at the initiative of the Union of Social-Humanitarian Organizations and originally named as the Association of physically disabled persons of Bitola, 
    the organization later decided to expand its activities to two more municipalities in the Pelagonia Region – Demir Hisar and Resen. </p>
    
    <p>
    MOBILNOST Bitola is an associate member of the National Union of Persons with Physical Disabilities of North Macedonia, and its main objective is to identify,
    register and activate persons with physical disabilities (cerebral palsy, paraplegia and quadriplegia, polio, muscular dystrophy, multiple sclerosis, 
    amputations and other neuromuscular diseases), to advocate for their rights (of education, social and health care, employment and overall inclusion in the 
    society), and to overcome the most important issue of accessibility. </p>
    
    <p>
    In the past three and a half decades, MOBILNOST Bitola has achieved a lot of its goals, such as the improvement of accessibility in public spaces, in 
    collaboration with institutions and organizations on local, national and international level, participating in multiple national and international projects, 
    organizing workshops and events on various topics and aspects of the needs and challenges of the persons with physical disabilities.</p>
    
    <p>
    Members of the Association are involved and are actively participating in various municipal and national bodies, providing the necessary insights for the 
    introduction and implementation of respective laws and regulations, international declarations and conventions. Among the most important projects MOBILNOST 
    Bitola has worked on is the Promotion of the Ratification and Implementation of United Nations Convention on the Rights of Persons with Disabilities in 2010, 
    which the Republic of North Macedonia ratified the following year.</p>
    `
  },


]
  
