import React from "react"
import { Button } from 'antd'
import classes from './styles.module.css'
import { Link } from 'react-router-dom'


interface Props{
  title: string
  text: string
  link: string
}

const RightsAndLegislationComponent: React.FC<Props> = ({title, text, link }:Props) => {

  return (

    <div className={classes.box} >
      <div className={classes.boxTitle}>{ title }</div>
      <div className={classes.boxText} dangerouslySetInnerHTML={{ __html: text.slice(0, 400)+ ' ...' }} ></div>
      <div className={classes.boxBtn}>
        <div className={classes.boxCenter}>
          {/* <Link to={ `/rights_and_legislation/${link}` }> */}
          <Link to={ `${link}` }>
            <Button size={'large'} className={classes.StartHereBtn}>READ MORE</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RightsAndLegislationComponent
