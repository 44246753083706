
import React from "react"
import Props from './svgProps'

const Hotel2Svg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 265.8 238.232"
        fill="none"
        opacity="0.3">
       
        <path id="Path_3056" data-name="Path 3056" d="M21.1,25.988l22.443-3.151c.788,0,1.186-.389,1.575-1.177L55.36,1.182c.788-1.575,2.762-1.575,3.151,0L68.75,21.661a5.369,5.369,0,0,0,1.575,1.177l22.443,3.151a1.981,1.981,0,0,1,1.177,3.151L77.8,44.892c-.389.389-.788,1.177-.389,1.566l3.938,22.453a1.823,1.823,0,0,1-2.762,1.964L58.511,60.247a1.187,1.187,0,0,0-1.575,0L36.855,70.875a1.881,1.881,0,0,1-2.762-1.964l3.938-22.453c0-.788,0-1.177-.389-1.566L21.5,29.139C19.138,27.953,19.527,26.377,21.1,25.988Z" transform="translate(168.305 0.005)" fill="#f7931e"/>
        <path id="Path_3057" data-name="Path 3057" d="M11.4,25.988l22.443-3.151c.788,0,1.186-.389,1.575-1.177L45.653,1.182c.788-1.575,2.762-1.575,3.151,0l10.24,20.479a5.369,5.369,0,0,0,1.575,1.177l22.443,3.151a1.981,1.981,0,0,1,1.177,3.151L68.1,44.892c-.389.389-.788,1.177-.389,1.566l3.938,22.453a1.823,1.823,0,0,1-2.762,1.964L48.8,60.247a1.187,1.187,0,0,0-1.575,0L27.148,70.875a1.881,1.881,0,0,1-2.762-1.964l3.938-22.453c0-.788,0-1.177-.389-1.566L11.794,29.139C9.421,27.953,9.82,26.377,11.4,25.988Z" transform="translate(85.866 0.005)" fill="#f7931e"/>
        <path id="Path_3058" data-name="Path 3058" d="M60.478,40.6A28.351,28.351,0,1,1,32.122,12.24,28.238,28.238,0,0,1,60.478,40.6" transform="translate(32.058 103.916)" fill="#f7931e"/>
        <path id="Path_3059" data-name="Path 3059" d="M1.686,25.988,24.13,22.838c.788,0,1.186-.389,1.575-1.177L35.944,1.182c.788-1.575,2.762-1.575,3.151,0l10.24,20.479a5.369,5.369,0,0,0,1.575,1.177l22.443,3.151a1.981,1.981,0,0,1,1.177,3.151L58.388,44.892C58,45.281,57.6,46.069,58,46.458l3.938,22.453a1.823,1.823,0,0,1-2.762,1.964L39.095,60.247a1.187,1.187,0,0,0-1.575,0L17.439,70.875a1.881,1.881,0,0,1-2.762-1.964l3.938-22.453c0-.788,0-1.177-.389-1.566L2.085,29.139C-.288,27.953.111,26.377,1.686,25.988Z" transform="translate(3.438 0.005)" fill="#f7931e"/>
        <path id="Path_3060" data-name="Path 3060" d="M265.8,151.236a3.388,3.388,0,0,1-3.151,3.151H244.144a3.388,3.388,0,0,1-3.151-3.151V124.067H24.816v27.169a3.388,3.388,0,0,1-3.151,3.151H3.151A3.4,3.4,0,0,1,0,151.236V13.027A3.388,3.388,0,0,1,3.151,9.876H21.665a3.388,3.388,0,0,1,3.151,3.151V99.65H265.8Z" transform="translate(0 83.846)" fill="#f7931e"/>
        <path id="Path_3061" data-name="Path 3061" d="M173.856,71.683H10.83V28.372A16.559,16.559,0,0,1,30.521,12.22l116.952,25.2a33.329,33.329,0,0,1,26.382,32.683Z" transform="translate(91.945 101.184)" fill="#f7931e"/>

      </svg>
    )
}

export default Hotel2Svg
