import React, { useState, useEffect }  from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"


import { RigtsAndLegislation_Rights_of_person_with_disabilities } from '../CommonPage/RightsAndLegislation/Rights_of_persons_with_disabilities'
import { RigtsAndLegislation_Protection_Of_The_Elderly } from '../CommonPage/RightsAndLegislation/Protection_of_the_elderly'

import { GoodPractices_Greece_Makes_Accessibility } from '../CommonPage/GoodPractices/Greece_makes_accessibility'
import { GoodPractices_Acropolis_To_Be_Accessible } from '../CommonPage/GoodPractices/Acropolis_to_be_accessible'
import { GoodPractices_Acropolis_Gets_Wheelchair_Lift } from '../CommonPage/GoodPractices/Acropolis_gets_wheelchair_lift'




import { useLocation } from "react-router-dom"
import HelmetComponent from '../../components/Helmet'
  
const CommonPage: React.FC = () => {


  const [data, setData] = useState<any>({})
  const [gp, setGp] = useState<boolean>(false)

  const location = useLocation()


  useEffect(() => {
    switch (location.pathname) {
    case '/rights_and_legislation/rights_of_persons_with_disabilities':
      setData(RigtsAndLegislation_Rights_of_person_with_disabilities);
      break;
    case '/rights_and_legislation/protection_of_the_elderly':
      setData(RigtsAndLegislation_Protection_Of_The_Elderly);
      break;
    case '/good_practices/greece_makes_accessibility':
        setData(GoodPractices_Greece_Makes_Accessibility);
        setGp(true);
      break;  
    case '/good_practices/acropolis_to_be_accessible':
        setData(GoodPractices_Acropolis_To_Be_Accessible);
        setGp(true);
        break;
    case '/good_practices/acropolis_gets_wheelchair_lift':
      setData(GoodPractices_Acropolis_Gets_Wheelchair_Lift);
      setGp(true);
    break;
  }


}, [location.pathname])


  return (
    <>
      <HelmetComponent title={data.header} descrition={data.header} />
      <div className={ gp ? classes.backgroundImage : classes.backgroundGrey}>
        <div className={ gp ? classes.containerGp :  classes.container}>
          <Row justify="center">
            <Col xs={{span:22}} lg={{span : 20}} >
              
                <div className={gp ? classes.title : classes.titleGp} dangerouslySetInnerHTML={{ __html: data.header }} ></div>
                <div className={classes.text} dangerouslySetInnerHTML={{ __html: data.text }} ></div>
             
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CommonPage
