import React from "react"
import { Row, Col, Button } from 'antd'
import wizzard from './wizzard.module.css'

import RestaurantSvg from '../../img/svg/restaurant'
import Restaurant2Svg from '../../img/svg/restaurant2'
import HotelSvg from '../../img/svg/hotel'
import Hotel2Svg from '../../img/svg/hotel2'

import {SearchOutlined } from '@ant-design/icons'


interface Props{
  gsisItems: any,
  IdPoint: (e: number) => void
}

const WizzardComponent: React.FC<Props> = ({ gsisItems, IdPoint }:Props ) => {


  // console.log(gsisItems[0].filters)

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

  return (
    <>
      <Row justify="center">
        <Col xs={{span:22}} lg={{span : 20}} >
          
          {gsisItems[0].pois.map((poi: any, index: number) => {

              return (
      
                <span key={index}>
                  <div className={wizzard.DestinationNumber}>Selected Destination (POI) {alphabet[index]}:</div>
                  <div className={wizzard.DestinationTitle}>{poi.title}</div>

                  
                  {poi.eat.length !== 0 &&
                    <div className={wizzard.EatCube}>
                      <Row justify="center" align='middle'>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                          <div className={wizzard.left}>
                            <RestaurantSvg width={66} height={66} fill='' /><span>Where to eat?</span>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                          <div className={wizzard.right}>
                            <Restaurant2Svg width={150} height={150} fill='' />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                  

                  <Row>
                    {poi.eat.map((item: any) => {
                      return (
                        <div key={item.title} >
                          <Button className={wizzard.ButtonRestaurant} onClick={() => IdPoint(item.id)}>
                            <span className={wizzard.ShowOrHide}><SearchOutlined /></span>
                            {item.title}
                          </Button>
                        </div>
                      )
                    })}
                  </Row>

                  {poi.sleep.length !== 0 &&
                    <div className={wizzard.SleepCube}>
                      <Row justify="center" align='middle'>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                          <div className={wizzard.left}>
                            <HotelSvg width={66} height={66} fill='' /><span>Where to sleep?</span>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                          <div className={wizzard.right}>
                            <Hotel2Svg width={150} height={150} fill='' />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                  <Row>
                    {poi.sleep.map((item: any) => {
                      return (
                        <div key={item.title} >
                          <Button className={wizzard.ButtonRestaurant} onClick={() => IdPoint(item.id)}>
                            <span className={wizzard.ShowOrHide}><SearchOutlined /></span>
                            {item.title}
                          </Button>
                        </div>
                      )
                    })}
                  </Row>
                  <hr className={wizzard.hr} />
                </span>
                 
            )})
          }

          
           <div className={wizzard.FiltersBlock}>
            <div className={wizzard.Filters}>FILTERS APPLIED FOR THIS PLANNED TRIP:</div>
            <Row>

              {
                gsisItems[0].filters.map((filter: any, index: number) => {
                  
                  return (
                    <Col key={index } xs={24} sm={24} md={index %2 == 0 ? 8 : 16} lg={index %2 == 0 ? 8 : 16} xl={index %2 == 0 ? 8 : 16}>
                      <div className={wizzard.Filter}>
                       - {filter}
                      </div>
                    </Col>
                  )

                })
              } 

            </Row>
          </div> 

        </Col>
      </Row>
    </>
  )
}

export default WizzardComponent