export const GoodPractices_Acropolis_To_Be_Accessible = {
  
  header: 'Good Practices',
  text:`
  <h3>Acropolis to be accessible to visually impaired visitors as of June 2022</h3>
  <img src='../img/GoodPractices/GoodPractices_2.jpg'  alt='Acropolis to be accessible'/>
  <div>
  The Acropolis in Athens and its monuments will become accessible to visually impaired individuals as of June 2022, Culture Minister Lina Mendoni announced during a scientific conference held at the Peace and Friendship Stadium in Athens.
  </div>
  <div>
  According to Mendoni, the ministry is collaborating with the “Lighthouse for the Blind of Greece” to create a four-language printed guide and special signs to facilitate people with visual problems.
  </div>
  <div>
  Speaking during the 1st Scientific Conference on the Accessibility of Persons with Disabilities in Public Areas, Mendoni referred to the “fundamental right of people’s access to culture” as well as to the ministry’s actions to improve access to museums, archaeological and cultural sites.
  </div>
  <div>
  “We implement or participate in actions and projects aimed to encourage and facilitate the participation of people with disabilities in cultural activities,” the minister said.
  </div>
  <div>
  According to the ministry, some 300 people with disabilities (on average) visited the Acropolis on a daily basis from June to October.
  </div>
  <div>
  “Culture contributes to the elimination of stereotypes and prejudices, encourages tolerance and respect for individuality and diversity, and contributes to democratic dialogue. Ensuring free access to culture for all citizens without exceptions is a matter of social equality and justice – a matter of democracy,” she added.
  </div>
  <h5>Ministry taking action to make culture accessible to all</h5>
  <div>
  Last year, the culture ministry unveiled a new lift and specially designed paths facilitating wheelchair access to the Acropolis monuments.
  </div>
  <div>
  The ministry this year has plans to construct new lifts in other archaeological sites such as the upper town of Monemvasia as well as to facilitate movement through the use of electric golf carts in Ancient Messini, Ancient Olympia, Pella and Nikopolis.
  </div>
  <div>
  <h5>The ministry’s initiatives also include:</h5>
  – the creation of accessible cultural content through traditional and digital media<br/>
  – the introduction of a digital discount card for people with disabilities<br/>
  – equal access to audiovisual cultural productions, but also to higher arts educational programs that will enable disabled individuals to take part in studies related to the accessibility of museums, cultural venues, archaeological sites, and monuments<br/>
  – the use of culture as a preventive and therapeutic activity<br/>
  – the development of educational programs focusing on social inclusion and multiculturalism<br/>
  – the development of seminars and workshops for the staff of the culture ministry and bodies active in modern culture and cultural heritage.<br/>
  </div>
  `
}


