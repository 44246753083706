import React, { useState, useEffect } from "react"
import classes from "./Search.module.css"
import { useTranslation } from "react-i18next"
import SvgClose from "./SvgClose"
import { useSearch } from './useSearch'
import { Link } from "react-router-dom";
import ModalComponent from '../../pages/Gsis/Modal'
import useSpeech from "use-speech18"
import { usePoints } from '../../hooks/useMap'


interface Props {
  onClick?(): void;
}

const Search: React.FC<Props> = ({ onClick }) => {

  const { t, i18n } = useTranslation()

  const { isLoading, isFetching, Points, Categories, FacilityArray, CategoryArray, refetchPoints } = usePoints()
  const [word, setWord] = useState<string>('')
  const [item, setItem] = useState<any>([])
  const [Results, setResults]= useState<any[]>([])
  
  const [openModal, setOpenModal] = useState<boolean>(false)

  // const { isLoading, error, Results } = useSearch(i18n.language === 'gr' ? 'el' : i18n.language, word)

  const [totalNumberOfResults, setTotalNumberOfResults] = useState<number>(1);


  useEffect(() => {
    

    

    let results = Points.filter((item) => { 
      return (
         item.title.toLowerCase().includes(word.toLowerCase())
      )
    })



    console.log('Word : ', word)
    console.log('Points : ', Points)
    console.log('results : ', results)
    setResults(results)
setTotalNumberOfResults(Object?.keys(results)?.length!)

  }, [Points, word])






  //////////////////////////////////////////      Speech Voices     ///////////////////////////////////////////
  const { Speak, speak_utils } = useSpeech()
  const [isSpeech, setSpeech] = useState<boolean>(true)


  const textToSpeech = (title: string) => { isSpeech && Speak(title) }

  function getSelectedText() {
    isSpeech && Speak(window?.getSelection()?.toString() || '')
  }

  useEffect(()=> {
    // console.log("array of avalaible voices", speak_utils.Voices)
    speak_utils.setVoice(speak_utils.Voices[2].name)
    // console.log(speak_utils.selectedVoice, "currently selected voice")   
  }, [speak_utils.Voices])


 //////////////////////////////////////////      end Speech Voices     ///////////////////////////////////////////

  
  
  
  const Timer = (e: any) => {

    setTimeout(() => {
      setWord(e)
    }, 300)
  }

 

  return (
    <>
      <div className={`${classes.search}`}>
        <SvgClose
          className={classes.closeButton}
          width={70}
          height={70}
          strokeWidth={2}
          onClick={() => onClick && onClick()}
        />

        <div className={`${classes.searchWrapper}`}>
          <div className={`${classes.searchContent}`}>
            <h1>Search</h1>
            <input
              type={"search"}
              placeholder={t("Πληκτρολογήστε τον όρο αναζήτησης") || ''}
              onChange={e => Timer(e.currentTarget.value)}
            />
          </div>

          <h1 className={classes.result_message}>
            {totalNumberOfResults > 0
              ? t("Βρέθηκαν") + " " + totalNumberOfResults + " " + t("αποτελέσματα")
              : (word == '') ? ''
                : (word != '') && t("Δεν βρέθηκαν αποτελέσματα αναζήτησης")
            }
          </h1>

          <div className={classes.results_list}>
            {Results &&
              Results.map((item: { [key: string]: any }, index) => (
                <span key={index}>
                  <div onClick={() => [setOpenModal(true), setItem(item)]}>
                    <div className={classes.result_item} key={item.id}>
                    
                      <section>
                        <h2>{item?.title}</h2>
                      </section>

                    </div>
                  </div>
                </span>
              )
              )
            }
          </div>

        </div>
      </div>

      <ModalComponent
        item={item}
        openModalBoolean={openModal}
        setOpenModalBoolean={() => setOpenModal(false)}
        getSelectedText={ getSelectedText }
      />


    </>
  )
}

export default Search
