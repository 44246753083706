import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { PointsProps, CategoriesProps } from "../services/props"
import { axiosGSIS, axiosPointsDataHome } from "../services/points"
import { axiosPage } from "../services/page"
import { useGeolocated } from "react-geolocated";


export const usePoints2 = () => {

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 100,
  });

  return { coords }
}

export const usePointsDataHome = () => {

  const [Points, setPoints] = useState <PointsProps[]>([]);

  const { isLoading, isFetching, error } = useQuery(['points'], () => axiosPointsDataHome.getPointsDataHome(), {

    onSuccess: (data:PointsProps[]) => {
      setPoints(data)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error,  Points }
}


export const usePoints = () => {

  const [Points, setPoints] = useState<PointsProps[]>([]);
  const [Categories, setCategories] = useState<CategoriesProps[]>([]);
  const [CategoryArray, setCategoriesArray] = useState<[]>([]);
  const [FacilityArray, setFacilitiesArray] = useState<any[]>([]);
  
  let Array:any=[]
  let CategoriesArray: any = []
  let FacilitiesArray: any[] = [[], [], [], []]
  let isFacilitiesArray: any[] = []
  
  // const ReduxLogin: any[] = useSelector((state: RootState) => state.Logged.Login)
  // console.log('ReduxLogin', ReduxLogin)

  const { isLoading, isFetching, error, refetch: refetchPoints } = useQuery(['points'], () => axiosGSIS.getPoints(), {

    refetchOnWindowFocus: true,
    enabled: true,

    onSuccess: (data:PointsProps[]) => {

      // console.log('data',data)

      data.map((point: any) => {

        let facebook: string = ''
        let twitter: string = ''
        let instagram: string = ''
        let youtube: string = ''
        
        if (point.social_media.length !== 0) {
          for (const value of point.social_media) {
            switch (value.description) {
              case 'Facebook' : facebook = value.url; break;
              case 'Twitter'  : twitter = value.url; break;
              case 'Instagram': instagram = value.url; break;
              case 'YouTube'  : youtube = value.url; break;
              default: break;
          }  
          }
        }

        let schedule: string = '\n '
        
        for (const value of point.schedule) 
          schedule += '<p><strong>' + value.day + ' (' + value.time_ranges[0].start + ' - ' + value.time_ranges[0].finish + ')</strong></p>\n ' 


        for (const value of point.accessibility_facilities) {

          switch (value.category.id) {
            case 38: FacilitiesArray[0].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // General
            case 39: FacilitiesArray[1].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Visual impairment
            case 40: FacilitiesArray[2].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Hearing impairment
            case 41: FacilitiesArray[3].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Physical impairment
            }
        }
        
        for (const value of point.accessibility_facilities) {

          if (value.is_supported)
          isFacilitiesArray.push(value.id)
          // switch (value.category.id) {
          //   case 38: FacilitiesArray[0].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // General
          //   case 39: FacilitiesArray[1].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Visual impairment
          //   case 40: FacilitiesArray[2].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Hearing impairment
          //   case 41: FacilitiesArray[3].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Physical impairment
          //   }
        }

        Array.push({
          id: point.id,
          title: point.title,
          description: point.description,
          address: point.address.address,
          category_id: point.category.id,
          category: point.category.title,
          website: point.website.url,
          email: point.email,
          telephone: point.telephone,
          images: point?.videos.length !== 0 ? [ ...point.images,  ...point.videos  ]  : point.images,

          lat: String(point.location.latitude),
          lon: String(point.location.longitude),

          visual: point.sight_rating,
          hearing: point.hearing_rating,
          physical: point.mobility_rating,

          facebook: facebook,
          twitter: twitter,
          instagram: instagram,
          youtube:youtube,

          schedule: schedule,
          favorite: point.is_favorite ? point.is_favorite : false,
          accessibility_facilities: FacilitiesArray,
          is_accessibility_facilities: isFacilitiesArray
          // favorite: Math.floor(Math.random() * 2) + 1 // random from 1 - 2
        })

        FacilitiesArray = [[], [], [], []]
        isFacilitiesArray = []
      })

      setPoints(Array)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })


  useQuery(['facilities'], () => axiosGSIS.getFacilities(), {

    onSuccess: (data) => {
      FacilitiesArray=[]
      for (const value of data) { 
        FacilitiesArray.push(value.id)
      }

      setFacilitiesArray(FacilitiesArray)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })


  useQuery(['categories'], () => axiosGSIS.getCategories(), {

    onSuccess: (data) => {

      for (const value of data) { 

        CategoriesArray.push(value.title)
      }
      

      setCategories(data)
      setCategoriesArray(CategoriesArray)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })






  return { isLoading, isFetching, error, Points, FacilityArray, Categories, CategoryArray, refetchPoints }
}


export const useFavoritePoints = () => {

  const [Points, setPoints] = useState<PointsProps[]>([]);
  const [Categories, setCategories] = useState<CategoriesProps[]>([]);
  const [CategoryArray, setCategoriesArray] = useState<[]>([]);
  const [FacilityArray, setFacilitiesArray] = useState<any[]>([]);
  
  let Array:any=[]
  let CategoriesArray: any = []
  let FacilitiesArray: any[] = [[], [], [], []]
  let isFacilitiesArray: any[] = []
  
 

  const { isLoading, isFetching, error } = useQuery(['favoritePoints'], () => axiosGSIS.getPoints(), {

    onSuccess: (data:PointsProps[]) => {

      // console.log('data',data)

      data.map((point: any) => {

        let facebook: string = ''
        let twitter: string = ''
        let instagram: string = ''
        let youtube: string = ''
        
        if (point.social_media.length !== 0) {
          for (const value of point.social_media) {
            switch (value.description) {
              case 'Facebook' : facebook = value.url; break;
              case 'Twitter'  : twitter = value.url; break;
              case 'Instagram': instagram = value.url; break;
              case 'YouTube'  : youtube = value.url; break;
              default: break;
          }  
          }
        }

        let schedule: string = '\n '
        
        for (const value of point.schedule) 
          schedule += '<p><strong>' + value.day + ' (' + value.time_ranges[0].start + ' - ' + value.time_ranges[0].finish + ')</strong></p>\n ' 


        for (const value of point.accessibility_facilities) {

          switch (value.category.id) {
            case 38: FacilitiesArray[0].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // General
            case 39: FacilitiesArray[1].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Visual impairment
            case 40: FacilitiesArray[2].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Hearing impairment
            case 41: FacilitiesArray[3].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Physical impairment
            }
        }
        
        for (const value of point.accessibility_facilities) {

          if (value.is_supported)
          isFacilitiesArray.push(value.id)
          // switch (value.category.id) {
          //   case 38: FacilitiesArray[0].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // General
          //   case 39: FacilitiesArray[1].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Visual impairment
          //   case 40: FacilitiesArray[2].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Hearing impairment
          //   case 41: FacilitiesArray[3].push({ id: value.id, is_supported: value.is_supported, title: value.title }); break; // Physical impairment
          //   }
        }

        if (point.is_favorite) 
   
        Array.push({
          id: point.id,
          title: point.title,
          description: point.description,
          address: point.address.address,
          category_id: point.category.id,
          category: point.category.title,
          website: point.website.url,
          email: point.email,
          telephone: point.telephone,
          images: point?.videos.length !== 0 ? [ ...point.images,  ...point.videos  ]  : point.images,

          lat: String(point.location.latitude),
          lon: String(point.location.longitude),

          visual: point.sight_rating,
          hearing: point.hearing_rating,
          physical: point.mobility_rating,

          facebook: facebook,
          twitter: twitter,
          instagram: instagram,
          youtube:youtube,

          schedule: schedule,
          favorite: point.is_favorite ? point.is_favorite : false,
          accessibility_facilities: FacilitiesArray,
          is_accessibility_facilities: isFacilitiesArray
          // favorite: Math.floor(Math.random() * 2) + 1 // random from 1 - 2
        })

        FacilitiesArray = [[], [], [], []]
          isFacilitiesArray = []
          
          
        })
      
       setPoints(Array)
      
      // }

      
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })


  useQuery(['facilities'], () => axiosGSIS.getFacilities(), {

    onSuccess: (data) => {
      FacilitiesArray=[]
      for (const value of data) { 
        FacilitiesArray.push(value.id)
      }

      setFacilitiesArray(FacilitiesArray)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })


  useQuery(['categories'], () => axiosGSIS.getCategories(), {

    onSuccess: (data) => {

      for (const value of data) { 

        CategoriesArray.push(value.title)
      }
      
      setCategories(data)
      setCategoriesArray(CategoriesArray)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })






  return { isLoading, isFetching, error, Points, FacilityArray, Categories, CategoryArray }
}





export const useRightsAndLegislations = () => {

  const [Data, setData] = useState <any[]>([]);

  const { isLoading, isFetching, error } = useQuery(['RightsAndLegislations'], () => axiosPage.getRightsAndLegislations(), {

    onSuccess: (data) => {
      setData(data)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Data }
}



export const useRightsAndLegislation = (id: string) => {

  const [Data, setData] = useState <any>([]);

  console.log(id)

  const { isLoading, isFetching, error } = useQuery(['useRightsAndLegislation', id], () => axiosPage.getRightsAndLegislation(id), {

    onSuccess: (data) => {
      setData(data[0])
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Data }
}



export const useGoodPractices = () => {

  const [Data, setData] = useState <any[]>([]);

  const { isLoading, isFetching, error } = useQuery(['GoodPractices'], () => axiosPage.getGoodPractices(), {

    onSuccess: (data) => {
      setData(data)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Data }
}



export const useGoodPractice = (id: string) => {

  const [Data, setData] = useState <any>([]);

  const { isLoading, isFetching, error } = useQuery(['useGoodPractice', id], () => axiosPage.getGoodPractice(id), {

    onSuccess: (data) => {
      setData(data[0])
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Data }
}







// export const usePointsLogged = () => {

//   const [Points, setPoints] = useState<PointsProps[]>([]);
//   const [Categories, setCategories] = useState<CategoriesProps[]>([]);
//   const [CategoryArray, setCategoriesArray] = useState<[]>([]);
  

//   let Array:any=[]
//   let CategoriesArray: any = []

//   const { isLoading, isFetching, error } = useQuery(['points'], () => axiosGSIS.getPointsLogged(), {

//     onSuccess: (data:PointsProps[]) => {

//       data.map((point: any) => {

//         let facebook: string = ''
//         let twitter: string = ''
//         let instagram: string = ''
//         let youtube: string = ''
        
//         if (point.social_media.length !== 0) {
//           for (const value of point.social_media) {
//             switch (value.description) {
//               case 'Facebook' : facebook = value.url; break;
//               case 'Twitter'  : twitter = value.url; break;
//               case 'Instagram': instagram = value.url; break;
//               case 'YouTube'  : youtube = value.url; break;
//               default: break;
//            }
//           }
//         }

//         let schedule: string = '\n '
        
//         for (const value of point.schedule)
//           schedule += '<p><strong>' + value.day + ' (' + value.time_ranges[0].start + ' - ' + value.time_ranges[0].finish + ')</strong></p>\n '

//         Array.push({
//           id: point.id,
//           title: point.title,
//           description: point.description,
//           address: point.address.address,
//           category_id: point.category.id,
//           category: point.category.title,
//           website: point.website.url,
//           email: point.email,
//           telephone: point.telephone,
//           images: point?.videos.length !== 0 ? [ ...point.images,  ...point.videos  ]  : point.images,

//           lat: String(point.location.latitude),
//           lon: String(point.location.longitude),

//           visual: point.sight_rating,
//           hearing: point.hearing_rating,
//           physical: point.mobility_rating,

//           facebook: facebook,
//           twitter: twitter,
//           instagram: instagram,
//           youtube:youtube,

//           schedule: schedule,
//           favorite: point.is_favorite
//           // favorite: Math.floor(Math.random() * 2) + 1 // random from 1 - 2
//         })
//       })

//       setPoints(Array)
//     },

//     onError: (error:any) => {
//       alert(error.message)
//     }
//   })

//   useQuery(['categories'], () => axiosGSIS.getCategories(), {

//     onSuccess: (data) => {

//       for (const value of data) {

//         CategoriesArray.push(value.title)
//       }
      

//       setCategories(data)
//       setCategoriesArray(CategoriesArray)
//     },

//     onError: (error:any) => {
//       alert(error.message)
//     }
//   })

//   return { isLoading, isFetching, error, Points, Categories, CategoryArray }
// }








// export const usePoint = () => {

//   const [Points, setPoints] = useState<PointsProps[]>([]);
//   const [Categories, setCategories] = useState<CategoriesProps[]>([]);
//   const [CategoryArray, setCategoriesArray] = useState<[]>([]);


//   let Array:any=[]
//   let CategoriesArray: any = []

//   const { isLoading, isFetching, error } = useQuery(['points'], () => axiosGSIS.getPoints(), {

//     onSuccess: (data:PointsProps[]) => {

//       data.map((point: any) => {

//         let facebook: string = ''
//         let twitter: string = ''
//         let instagram: string = ''
//         let youtube: string = ''
        
//         if (point.social_media.length !== 0) {
//           for (const value of point.social_media) {
//             switch (value.description) {
//               case 'Facebook' : facebook = value.url; break;
//               case 'Twitter'  : twitter = value.url; break;
//               case 'Instagram': instagram = value.url; break;
//               case 'YouTube'  : youtube = value.url; break;
//               default: break;
//            }  
//           }
//         }

//         let schedule: string = '\n '
        
//         for (const value of point.schedule) 
//           schedule += '<p><strong>' + value.day + ' (' + value.time_ranges[0].start + ' - ' + value.time_ranges[0].finish + ')</strong></p>\n ' 

//         Array.push({
//           id: point.id,
//           title: point.title,
//           description: point.description,
//           address: point.address.address,
//           category_id: point.category.id,
//           category: point.category.title,
//           website: point.website.url,
//           email: point.email,
//           telephone: point.telephone,
//           images: point?.videos.length !== 0 ? [ ...point.images,  ...point.videos  ]  : point.images,

//           lat: String(point.location.latitude),
//           lon: String(point.location.longitude),

//           visual: point.sight_rating,
//           hearing: point.hearing_rating,
//           physical: point.mobility_rating,

//           facebook: facebook,
//           twitter: twitter,
//           instagram: instagram,
//           youtube:youtube,

//           schedule: schedule,

//           favorite: Math.floor(Math.random() * 2) + 1 // random from 1 - 2
//         })
//       })

//       setPoints(Array)
//     },

//     onError: (error:any) => {
//       alert(error.message)
//     }
//   })

//   useQuery(['categories'], () => axiosGSIS.getCategories(), {

//     onSuccess: (data) => {

//       for (const value of data) { 

//         CategoriesArray.push(value.title)
//       }
      

//       setCategories(data)
//       setCategoriesArray(CategoriesArray)
//     },

//     onError: (error:any) => {
//       alert(error.message)
//     }
//   })

//   return { isLoading, isFetching, error, Points, Categories, CategoryArray }
// }



export const useAddRemoveFavoriteHook = (id: number) => {

  const { refetch: refetchAddRemoveFavorite } = useQuery(['addRemoveFavorite'], () => axiosGSIS.addRemoveFavorite(id), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {

      // console.log(data)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })
  return { refetchAddRemoveFavorite }
}






