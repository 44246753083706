import React from "react"
import Props from './svgProps'
    
const CultureSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g id="Group_1146" data-name="Group 1146" clipPath="url(#clip-path)">
          <path id="Path_3023" data-name="Path 3023" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#3fa9f5"/>
          <path id="Path_3024" data-name="Path 3024" d="M39.879,15.438c-1.491,0-2.982,1.491-5.964,1.491s-4.474-1.491-5.965-1.491a1.639,1.639,0,0,0-1.491,1.491V27.367c0,3.3,1.491,7.456,7.456,7.456s7.455-4.152,7.455-7.456V16.929A1.639,1.639,0,0,0,39.879,15.438ZM28.7,22.521a1.864,1.864,0,1,1,1.864,1.864A1.863,1.863,0,0,1,28.7,22.521m5.219,9.319a4.334,4.334,0,0,1-4.474-2.981h8.947A4.334,4.334,0,0,1,33.915,31.84Zm3.355-7.455a1.864,1.864,0,1,1,1.864-1.864,1.865,1.865,0,0,1-1.864,1.864" fill="#fff"/>
          <path id="Path_3025" data-name="Path 3025" d="M22.309,27.309a11.366,11.366,0,0,0,1.167.058,10.489,10.489,0,0,0,1.492-.093V22.9H19a4.333,4.333,0,0,1,4.473-2.983,3.477,3.477,0,0,1,1.492.279V16.93a3.191,3.191,0,0,1,1.49-2.983,2.67,2.67,0,0,1,2.983,0l1.491.747V9.474a1.639,1.639,0,0,0-1.491-1.49c-1.491,0-2.983,1.49-5.965,1.49S19,7.984,17.512,7.984v0a1.64,1.64,0,0,0-1.491,1.491V19.911a8.787,8.787,0,0,0,.922,4.061c.264.111.527.228.786.36A23.532,23.532,0,0,1,22.309,27.309ZM20.121,13.2a1.865,1.865,0,1,1-1.863,1.864A1.865,1.865,0,0,1,20.121,13.2" fill="#fff"/>
          <path id="Path_3026" data-name="Path 3026" d="M14.852,39.428a7.176,7.176,0,1,0-3.509-.919h-.775a4.843,4.843,0,0,1-4.826-4.826V26.789a2.793,2.793,0,0,0-2.789-2.772H1.925a.46.46,0,0,0,0,.92H2.962a1.871,1.871,0,0,1,1.87,1.87V33.7a5.763,5.763,0,0,0,5.746,5.746h4.274Zm0-13.418a6.26,6.26,0,1,1-4.427,1.832A6.268,6.268,0,0,1,14.852,26.01Z" fill="#fff"/>
          <path id="Path_3027" data-name="Path 3027" d="M13.032,28.678a1.82,1.82,0,1,1,1.82,1.82,1.82,1.82,0,0,1-1.82-1.82" fill="#fff"/>
          <path id="Path_3028" data-name="Path 3028" d="M13.032,35.859a1.82,1.82,0,1,1,1.82,1.82,1.82,1.82,0,0,1-1.82-1.82" fill="#fff"/>
          <path id="Path_3029" data-name="Path 3029" d="M13.944,32.267a.907.907,0,1,1,.908.908.908.908,0,0,1-.908-.908" fill="#fff"/>
          <path id="Path_3030" data-name="Path 3030" d="M9.44,32.267a1.82,1.82,0,1,1,1.82,1.82,1.82,1.82,0,0,1-1.82-1.82" fill="#fff"/>
          <path id="Path_3031" data-name="Path 3031" d="M16.622,32.267a1.82,1.82,0,1,1,1.819,1.82,1.82,1.82,0,0,1-1.819-1.82" fill="#fff"/>
        </g>
      </svg>
    )
}

export default CultureSvg  