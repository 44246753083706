export const ProjectData = {
  title: 'The Project',
  TitleText: 'The main goal of the project is to motivate the transformation of a territory into an accessible and inclusive tourist destination and support the sustainable development of heritage tourism in the cross-border area. There as contributing to the development of a cross-border partnership, with the involvement and participation of the various agents of the tourist value chain that provide quality services to tourists with special needs.',
  subTitle: 'The project aims to:',
  subTitleText:`
  <ul>

  <li>•&nbsp; Exchange and import know-how and good practices related to the improvement of the accessibility of cultural and natural heritage structures. </li>
  <li>•&nbsp; Improve the accessibility and visitability of selected cultural and natural heritage assets in the cross - border area.</li>
  <li>•&nbsp; Improve the capacity of authorities responsible for the management of cultural and natural heritage assets, and of professionals from the tourist field(guides, travel and event agents, transportation services) in better addressing the needs of senior and accessible tourism(capacity building activities). </li>
  <li>•&nbsp; Develop a platform at which all disabled and elderly visitors would be able to design their trip to the CB area and be able through this platform(G.I.S.) to accomplish all the arrangements for their vacation trip. </li>
  <li>•&nbsp; Collect data related to the accessibility and friendliness to disabled and elderly visitors of key cultural and natural assets of the CB area(field studies). </li>
  <li>•&nbsp; Improve the visibility of cultural and natural heritage assets that are accessible and friendly to all, through electronic and physical media, campaigns, etc.and by producing an informative guide for all visitors with useful information for the elderly and the disabled(branding & promotion). </li>
  <li>•&nbsp; Conduct a joint strategic planning study towards branding the CB area as an international tourist destination for elderly and disabled, of high quality at low to medium cost.</li>
  
  </ul>
  `
}



