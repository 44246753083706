export const GoodPractices_Acropolis_Gets_Wheelchair_Lift = {
  
  header: 'Good Practices',
  text:`
<h3>Acropolis gets wheelchair lift, now accessible to disabled visitors</h3>
<img src='../img/GoodPractices/GoodPractices_3.jpg'  alt='Acropolis gets wheelchair lift'/>
<div>
Visitors with mobility issues will now have access to the Acropolis monuments in Athens thanks to a new lift and specially designed paths facilitating wheelchair access.
</div>
<div>
Describing it as “an emblematic project”, Prime Minister Kyriakos Mitsotakis inaugurated the lift on December 3, International Day of Disabled Persons.
</div>
<div>
“Today, we inaugurate the new lift and also the designed routes in order to make the Acropolis Hill accessible to everyone,” the Prime Minister said.
</div>
<div>
The modern lift, which replaces a repeatedly malfunctioning elevator, is located on the north face of the Acropolis along the ancient promenade and will transport wheelchair users and individuals with mobility issues to the top of the hill.
</div>
<h5>New pathways</h5>
<div>
The specially-designed and renovated 500-meter-long and four-meter-wide pathways also provide access to the 5th century BC World Heritage site.
</div>
<div>
Together with Culture Minister Lina Mendoni and Antonis Papadimitriou, president of the Onassis Foundation, which funded the project, Mitsotakis said it demonstrates that the Greek state can work with private entities that “share the same vision and philosophy” to “deliver to the world a very important project”.
</div>
<div>
He went on to refer to the importance of ensuring “accessibility for everyone and everywhere”, which is behind the government’s national plan for the rights of people with disabilities to which he is personally committed.
</div>
<div>
On her part and in response last month to reactions concerning the repair of the special paths, Mendoni said the work was supervised by restoration expert Manolis Korres, a civil engineer, professor of architectural history and head of the Acropolis Restoration Service (YSMA).
</div>
<div>
Papadimitriou said the Acropolis was a “monument of universal significance” for all people and “a bright beacon of optimism”.
</div>
<div>
The new lift and pathways as well as a new lighting system showcasing the landmark site and its surrounding monuments unveiled in September are part of an overhaul project aimed at making one of the world’s most visited sites accessible to all.
</div>
`
}


