import React from "react"
import Props from './svgProps'
    
const HotelsSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_2993" data-name="Path 2993" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#f7931e"/>
          <path id="Path_2994" data-name="Path 2994" d="M30.428,13.677l2.365-.332c.083,0,.125-.041.166-.124l1.079-2.158c.083-.166.291-.166.332,0l1.079,2.158a.566.566,0,0,0,.166.124l2.365.332a.209.209,0,0,1,.124.332l-1.7,1.66c-.041.041-.083.124-.041.165l.415,2.366a.192.192,0,0,1-.291.207l-2.116-1.12a.125.125,0,0,0-.166,0l-2.116,1.12A.2.2,0,0,1,31.8,18.2l.415-2.366c0-.083,0-.124-.041-.165l-1.7-1.66C30.221,13.884,30.262,13.718,30.428,13.677Z" fill="#fff"/>
          <path id="Path_2995" data-name="Path 2995" d="M20.718,13.677l2.365-.332c.083,0,.125-.041.166-.124l1.079-2.158c.083-.166.291-.166.332,0l1.079,2.158a.566.566,0,0,0,.166.124l2.365.332a.209.209,0,0,1,.124.332l-1.7,1.66c-.041.041-.083.124-.041.165l.415,2.366a.192.192,0,0,1-.291.207l-2.116-1.12a.125.125,0,0,0-.166,0l-2.116,1.12a.2.2,0,0,1-.291-.207l.415-2.366c0-.083,0-.124-.041-.165l-1.7-1.66C20.51,13.884,20.552,13.718,20.718,13.677Z" fill="#fff"/>
          <path id="Path_2996" data-name="Path 2996" d="M20.221,26.167a2.988,2.988,0,1,1-2.988-2.988,2.976,2.976,0,0,1,2.988,2.988" fill="#fff"/>
          <path id="Path_2997" data-name="Path 2997" d="M11.009,13.677l2.365-.332c.083,0,.125-.041.166-.124l1.079-2.158c.083-.166.291-.166.332,0l1.079,2.158a.566.566,0,0,0,.166.124l2.365.332a.209.209,0,0,1,.124.332l-1.7,1.66c-.041.041-.083.124-.041.165l.415,2.366a.192.192,0,0,1-.291.207l-2.116-1.12a.125.125,0,0,0-.166,0l-2.116,1.12a.2.2,0,0,1-.291-.207l.415-2.366c0-.083,0-.124-.041-.165l-1.7-1.66C10.8,13.884,10.843,13.718,11.009,13.677Z" fill="#fff"/>
          <path id="Path_2998" data-name="Path 2998" d="M38.478,35.711a.357.357,0,0,1-.332.332H36.2a.357.357,0,0,1-.332-.332V32.848H13.084v2.863a.357.357,0,0,1-.332.332H10.8a.358.358,0,0,1-.332-.332V21.147a.357.357,0,0,1,.332-.332h1.951a.357.357,0,0,1,.332.332v9.128H38.478Z" fill="#fff"/>
          <path id="Path_2999" data-name="Path 2999" d="M38.478,29.154H21.3V24.59a1.744,1.744,0,0,1,2.075-1.7L35.7,25.544a3.512,3.512,0,0,1,2.78,3.444Z" fill="#fff"/>
        </g>
      </svg>
    )
}

export default HotelsSvg  