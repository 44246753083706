import React from 'react'
import { Row, Col } from 'antd'
import classes from "./css/Slider.module.css"

const Slider: React.FC = () => {

  return (
    <>
      <div className={classes.container}>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.sliderContainer}> 
              <img className={classes.image} alt="Chania image 1" src="./img/main.jpg" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Slider
