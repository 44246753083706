import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { useSelector } from 'react-redux'


interface GsisPlannedArray{ value: number }
interface GsisPlannedState{ GsisPlanned: GsisPlannedArray[] }
const initialState: GsisPlannedState = { GsisPlanned: [] }

export const gsisPlannedSlice = createSlice({
  name: 'gsisPlanned',
  initialState,
  reducers: {
    AddToGsisPlannedSlice: (state, action) => {
      state.GsisPlanned.push(action.payload)
    },

    deleteFromGsisPlanned: (state, action) => {
      // state.Basket = state.Basket.findIndex((item) => item.id === action.payload, 1)
      state.GsisPlanned.splice(state.GsisPlanned.findIndex((arrow: any) => arrow.id === action.payload), 1);
    },

    deleteAllFromGsisPlanned: () => initialState,

    changeGsisPlanned: (state, action) => {
      state.GsisPlanned=(action.payload)
    }

  },
})

export const { AddToGsisPlannedSlice, deleteFromGsisPlanned, deleteAllFromGsisPlanned, changeGsisPlanned } = gsisPlannedSlice.actions
export default gsisPlannedSlice.reducer 