import React from "react"
import HelmetComponent from '../../components/Helmet'


import Slider from './components/Slider'
import Search from './components/Search'
import Plan from './components/Plan'
import Legislation from './components/Legislation'
import GoodPractices from './components/GoodPractices'


const Home = () => {

  
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  // window.localStorage.clear()

  return (
    <>
      <HelmetComponent title='Alter Trip' descrition='Alter Trip' />

      <Slider />
      <Plan /> 
      <Legislation />
      <GoodPractices />
     
    </>
  )
}

export default Home