import sites from "./svg_pointers/icon_sites.svg"
import museums from "./svg_pointers/icon_museums.svg"
import various from "./svg_pointers/icon_various.svg"
import religious from "./svg_pointers/icon_religious.svg"
import hotels from "./svg_pointers/icon_hotels.svg"
import restaurants from "./svg_pointers/icon_restaurants.svg"
import culture from "./svg_pointers/icon_culture.svg"


const SvgPointer = [sites, museums, various, religious, hotels, restaurants, culture]

export default SvgPointer;
