export const RigtsAndLegislation_Rights_of_person_with_disabilities = {
  
  header: 'Rights and legislation',
  text:`
  <h3>RIGHTS OF PERSONS WITH DISABILITIES</h3>
  <h4>A. NATIONAL LEGAL FRAMEWORK</h4>
  <h5>A.1. INTERNATIONAL CONVENTIONS</h5>
  <div>
  - Particular mention should be made of the Convention on the Rights of Persons with Disabilities, signed in New York on March 30, 2007, and the Optional Protocol to the Convention on the Rights of Persons with Disabilities, signed in New York on 27 September 2010, instruments which were ratified by Act 4074/2012 (Official Gazette issue no. 88 A’).
  </div>
  <h5>A.2. EUROPEAN LAW</h5>
  <div>
  - The Charter of Fundamental Rights provides, in article 26, inclusion of persons with disabilities.
  </div>
  <div>
  - Act 3304/2005 on the “Implementation of the principle of equal treatment, irrespective of racial or ethnic origin, religion or belief, disability, age or sexual orientation » transposed into the Greek law: a) Directive 2000/43/EC on “Implementing the principle of equal treatment between persons, irrespective of their racial or ethnic origin” and b) Directive 2000/78/EC “On combating discrimination on ground s of religion or belief, disability, age or sexual orientation”.
  </div>
  <h5>A.3. CONSTITUTION LAW</h5>
  <div>
  - Protection of the family, as the foundation of maintenance and advancement of the Nation, is constitutionally guaranteed in article 21 which, among others, provides for special care for large families, persons with disabilities or war victims, widows, orphans of those who fell in the war, etc. Special State care is provided for the protection of youth, the elderly, persons with disabilities and to care for the needy. Paragraph 6 of the same article states that “Persons with disabilities have the right to enjoy measures ensuring their independence, occupational inclusion and participation in the social, economic and political life of the country.”.
  </div>
  <div>
  - Act 2430/1996 establishes the 3rd of December as the “Day for the Persons with Disabilities”.
  </div>
  <div>
  - Act 2447/1996, which reformed family law and introduced the institution of guardianship. Article 1666 of the Civil Code identifies the persons subject to guardianship, such as:
  </div>
  
  a) Persons who, due to mental or intellectual disorder, are unable, in whole or in part, to care by themselves for their own affairs<br/>
  b) Persons who are unable, because of physical disability, in whole or in part, to care by themselves for their own affairs .<br/>
  <div>
  - Act 2643/98 on the “Care for the employment of special categories of persons and other provisions”, regulates the quota of compulsory placement of persons with disabilities and other protected social groups in the public and private sectors.
  </div>
  <div>
  - Act 11 43/1981 “On the Special Education, Special Vocational Training, Employment and Social Welfare of individuals diverging from normal behavior and certain other educational provisions”, Act 1566/1985 on the “Structure and function of the primary and secondar y education and other provisions” and, especially, Act 2817/2000, which supplemented and upgraded the existing legislative framework for special education, are the most important legislation on special education.
  </div>
  <div>
  - Act 2817/2000 identifies as persons having special educational needs those who:
  a) have cognitive impairment or immaturity.<br/>
  b) have particularly severe visual or hearing problems.<br/>
  c) have serious neurological or orthopaedic defects or health problems.<br/>
  d) have speech impediments.<br/>
  e) have specific learning difficulties, such as dyslexia, dyscalculia, dysanagnosia.<br/>
  f) have complex cognitive, emotional and social difficulties and those who have autism and other development disorders.<br/>
  </div>
  <div>
  - Act 3304/2005 prohibits direct or indirect discrimination and harassment on grounds of disability, among others, to all persons in the public and private sector, as regards terms of access to work and employment, access to all types and levels of vocational guidance, employment terms and conditions etc.
  </div>
  <div>
  - Per Act 3996/2011, on “Reforming Labor Inspectorate, regulating social security issues and other provisions”, Labor Inspectorate monitors compliance with equal treatment against persons with disabilities and ensures that employers make all reasonable adjustments by ta king all appropriate measures to ensure access and retention of persons with disabilities into work.
  </div>
  <div>
  - In the context of instituting in Greece the Social Economy and Social Enterprise area , the provisions of Act 4019/2011 establish a new form of social cooperative enterprises for the inclusion of persons belonging to vulnerable groups of the population into economic and social life.
  </div>
  `
}


