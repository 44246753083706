import React from "react"
import Props from './svgProps'
    
const SearchSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 42 42">
          <path id="search_icon" data-name="search icon" d="M1486.017,140.99a.959.959,0,0,1-.682-.284l-9.362-9.457a12.144,12.144,0,1,1,4.147-9.139,12.153,12.153,0,0,1-2.8,7.754l9.376,9.471a.975.975,0,0,1,0,1.371A.957.957,0,0,1,1486.017,140.99Zm-17.957-29.07a10.19,10.19,0,1,0,10.15,10.19A10.182,10.182,0,0,0,1468.06,111.92Z" transform="translate(-1456 -110)" fill="#864f8b"/>
      </svg>
      
    )
}

export default SearchSvg  