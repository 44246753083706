
import React from "react"
import Props from './svgProps'

const Restaurant2Svg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 262.844 240.135"
        fill="none"
        opacity="0.3">
       
        <path data-name="Path 3053" d="M151.89,100.487c-10.486,10.478-18.338,18.33-25.326,23.573-3.49-3.49-6.988-6.988-12.223-11.35,13.1-19.211,8.733-47.155-11.35-66.365C81.154,24.516,24.4-3.428,6.064,14.91s8.733,75.1,30.561,97.8c19.211,19.211,48.027,23.573,66.357,11.35l11.35,11.35c-16.585,13.1-24.446,16.593-63.74,45.41-55.015,41.039-12.223,90.811,31.434,32.306,29.689-39.294,33.179-47.155,46.282-62.875,18.33,21.836,9.605,17.466,44.529,64.62,38.422,53.262,87.329,12.223,30.569-30.561-47.155-36.677-41.92-27.071-63.748-47.155,6.107-6.98,13.968-14.84,24.446-25.318,10.486,6.98,25.326,6.98,37.549-5.243l55.015-62.867c4.362-5.243,8.725-9.605,4.362-14.848L194.674,95.244c-3.5,3.49-8.733-2.625-6.115-5.243L255.8,22.763c-1.745-1.745-2.617-3.49-4.37-5.235L184.2,84.766c-3.5,3.49-8.733-2.625-6.115-5.243l67.238-67.238q-2.629-2.617-5.243-5.235L173.719,74.28c-3.5,3.5-9.605-2.617-6.115-5.235L233.968,1.807c-5.243-4.362-8.733,0-13.976,4.37L157.125,61.185c-13.1,13.1-12.223,27.944-5.243,39.294Zm6.98,92.556c18.338,27.071,38.422,52.4,62.875,41.92C192.929,241.07,168.476,202.648,158.87,193.043ZM7.809,46.344c7.86,45.41,47.155,95.182,88.194,76.843C61.071,132.793,20.9,86.519,7.809,46.344ZM193.8,110.092c-21.828,3.49-44.537-19.211-40.167-40.167C143.157,97,166.731,120.57,193.8,110.092M72.429,167.725c-27.944,19.211-54.134,39.294-42.784,64.612-6.115-30.561,33.179-55.007,42.784-64.612" transform="translate(0.003 0.002)" fill="#39b54a" fillRule="evenodd" />
        
      </svg>
    )
}

export default Restaurant2Svg
