import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'



interface BasketArray{ value: number }
interface BasketState{ Basket: BasketArray[] }
const initialState: BasketState = { Basket: [] }


export const BasketSlice = createSlice({
  name: 'Basket',
  initialState,
  reducers: {
    AddToBasket: (state, action) => {
      state.Basket.push(action.payload)
    },

    deleteItemFromBasket: (state, action) => {
      // state.Basket = state.Basket.findIndex((item) => item.id === action.payload, 1)
      state.Basket.splice(state.Basket.findIndex((arrow: any) => arrow.id === action.payload), 1);
    },

    deleteAllFromBasket: () => initialState,

    changeBasket: (state, action) => {
      state.Basket=(action.payload)
    },
  
    // Municipality: (state, action) => {
    //   state.Municipality.splice(0, state.Municipality.length);
    //   if(action.payload !== '')
    //     state.Municipality.push(action.payload)
      
    // }
  },
})

export const { AddToBasket, deleteItemFromBasket, deleteAllFromBasket, changeBasket } = BasketSlice.actions
export default BasketSlice.reducer 