
import React from "react"
import Props from './svgProps'

const RestaurantSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62"
        fill="none">
       
        <path data-name="Path 3054" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#39b54a"/>
        <path data-name="Path 3055" d="M27.031,22.834a33.84,33.84,0,0,1-3.164,2.945c-.436-.436-.873-.873-1.527-1.418,1.636-2.4,1.091-5.891-1.418-8.291C18.194,13.343,11.1,9.852,8.813,12.143S9.9,21.525,12.631,24.361c2.4,2.4,6,2.945,8.29,1.418L22.339,27.2c-2.072,1.637-3.054,2.073-7.963,5.673C7.5,38,12.849,44.215,18.3,36.906c3.709-4.909,4.145-5.891,5.782-7.855,2.29,2.728,1.2,2.182,5.564,8.073,4.8,6.654,10.909,1.527,3.818-3.818-5.891-4.582-5.237-3.382-7.964-5.891.763-.872,1.745-1.854,3.054-3.163a3.61,3.61,0,0,0,4.691-.655l6.873-7.854c.545-.655,1.09-1.2.545-1.855l-8.29,8.291c-.437.436-1.091-.328-.764-.655l8.4-8.4c-.218-.218-.327-.436-.546-.654l-8.4,8.4c-.437.436-1.091-.328-.764-.655l8.4-8.4-.655-.654-8.29,8.4c-.437.437-1.2-.327-.764-.654l8.291-8.4c-.655-.545-1.091,0-1.746.546l-7.854,6.872a3.77,3.77,0,0,0-.655,4.909ZM27.9,34.4c2.291,3.382,4.8,6.546,7.855,5.237C32.158,40.4,29.1,35.6,27.9,34.4ZM9.031,16.07c.982,5.673,5.891,11.891,11.018,9.6C15.685,26.87,10.667,21.089,9.031,16.07Zm23.236,7.964c-2.727.436-5.564-2.4-5.018-5.018a3.774,3.774,0,0,0,5.018,5.018M17.1,31.234c-3.491,2.4-6.763,4.909-5.345,8.072-.764-3.818,4.145-6.872,5.345-8.072" fill="#fff" fillRule="evenodd"/>


      </svg>
    )
}

export default RestaurantSvg
