import React, {useState, useEffect} from "react"
import { Checkbox, Col, Collapse, Tooltip, Popover } from 'antd'
import classes from "./styles.module.css"
import { RightOutlined } from '@ant-design/icons'


//    Redux
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'


const { Panel } = Collapse;

interface Props{
  array: any;
  collapse: string
}

const CheckBoxMapComponent: React.FC<Props> = ({ array, collapse }) => {

  const [visible, setVisible] = useState<boolean>(false)

  const basket: any[] = useSelector((state: RootState) => state.Basket.Basket)


  return (
    <>
      {array.slice(0, 4).map((item: any, index: number) => {
        return (
          <span key={index}>
            <Col span={24} key={item}>
          
              {basket.length !== 0 ?  
                <Popover
                  placement="topLeft"
                  title={'Please empty your trip planner before applying different filters'}
                >
                  <Checkbox value={item.id} className={classes.checkboxGroup} disabled={basket.length !== 0 ? true : false}>{item.title}</Checkbox>
                </Popover >
                :
                <Checkbox value={item.id} className={classes.checkboxGroup} disabled={basket.length !== 0 ? true : false}>{item.title}</Checkbox>
            }   

            </Col>
          </span>
        )
      })
      }
      {array.length > 4 &&
        
        <Collapse
          ghost
          activeKey={[collapse]}
          onChange={() => setVisible(!visible)}
          expandIcon={() => !visible ? <RightOutlined rotate={0} /> : <RightOutlined rotate={90} />} >
          <Panel header={'Show All' + ' (' + array.length + ')'} key='1' className={classes.collapse} >

            {array.slice(4, array.length).map((item: any, index: number) => {

              return (
                <span key={index}>
                  <Col span={24} key={item} >
                    {basket.length !== 0 ?
                      <Popover
                        placement="topLeft"
                        title={'Please empty your trip planner before applying different filters'}
                      >
                        <Checkbox value={item.id} className={classes.checkboxGroup} disabled={basket.length !== 0 ? true : false}>{item.title}</Checkbox>
                      </Popover >
                      :
                      <Checkbox value={item.id} className={classes.checkboxGroup} disabled={basket.length !== 0 ? true : false}>{item.title}</Checkbox>
                    }
                  </Col>
                </span>
              )
            })}

          </Panel>
        </Collapse >
      }
      {
        visible &&

        <div>
          {array.slice(4, array.length).map((item: any) => {
            return (
              <Col span={24} key={item} >
                {basket.length !== 0 ?
                  <Popover
                    placement="topLeft"
                    title={'Please empty your trip planner before applying different filters'}
                  >
                    <Checkbox value={item.id} className={classes.checkboxGroup} disabled={basket.length !== 0 ? true : false}>{item.title}</Checkbox>
                  </Popover >
                  :
                  <Checkbox value={item.id} className={classes.checkboxGroup} disabled={basket.length !== 0 ? true : false}>{item.title}</Checkbox>
                }
              </Col>
            )
          })
          }
        </div>
      }        

    </>
  )
}

export default CheckBoxMapComponent