export const GoodPractices_Greece_Makes_Accessibility = {
  
  header: 'Good Practices',
  text:`
  <h3>Greece makes accessibility for all travelers priority</h3>
  <img src='../img/GoodPractices/GoodPractices_1.jpg'  alt='Greece makes accessibility'/>
  <div>
  Greece took one more step towards ensuring its destinations are fully accessible to residents and travelers after Tourism Minister Vassilis Kikilias signed a protocol of cooperation with the National Confederation of Persons with Disabilities (ESAmeA).
  </div>
  <div>
  Kikilias met with ESAmeA President Ioannis Vardakastanis to lay out the framework of cooperation on shared actions towards accessibility, including the provision of comprehensive accessibility information, the inclusion of relevant curriculum at tourism schools on all levels, the development of education and training programs for people with disabilities in tourism professions and support actions for their employment and inclusion in the labor market.
  </div>
  <div>
  The two sides also agreed to jointly organize an annual accessible tourism forum to be held at a different destination each year.
  </div>
  <div>
  Kikilias said establishing a national strategy that would “ensure accessible tourism for all was a government priority”, with the goal of making Greece accessible to all its visitors.
  </div>
  <div>
  He added that a move in this direction would also upgrade the overall tourism product and facilitate at the same time the extension of the tourism season.
  </div>
  <div>
  Kikilias said that the ministry would be tapping into 320 million euros from the EU’s RFF tool to upgrade or create infrastructure projects which include accessibility infrastructure at tourist destinations, beaches, and businesses as well as the development of accessible travel products.
  </div>
  <div>
  In December 2021, he said plans were underway to make popular tourist destinations accessible to all people with disability, announcing new certification of accessible tourist enterprises and beaches.
  </div>
  <div>
  At the same time, Culture Minister Lina Mendoni said the Acropolis in Athens and its monuments would become accessible to the visually impaired as of June 2022. The UNESCO World Heritage Site became accessible to visitors with mobility issues after the installation last year of a new lift and of specially designed paths facilitating wheelchair access.
  </div>
  `
}


