import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import point from "../../img/point.svg"
import i_am from "../../img/i_am.svg"
import { usePoints2 } from '../../hooks/useMap'


L.Marker.prototype.options.icon = L.icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [0,0]
});


// L.Marker.prototype.options.icon = L.icon({
//   iconUrl: i_am,
//   popupAnchor: [0, -20],
//   iconSize: [40,40]
// });

const Routing = ({ IamLat, IamLng, Tolat, Tolon }) => {
 
  const { coords } = usePoints2()

  const map = useMap();

  const [iamLat, setIamLat] = useState(0)
  const [iamLon, setIamLon] = useState(0)

  useEffect(() => {
    if (coords) {
      setIamLat(coords.latitude)
      setIamLon(coords?.longitude) 
    }
  }, [coords])

  useEffect(() => {

    if (!map) return

    const routingControl = L.Routing.control({

      waypoints: [ L.latLng(IamLat, IamLng), L.latLng(Tolat, Tolon)],
      routeWhileDragging: true,
      lineOptions: {
        styles: [{ color: "green", weight: 4 }]
      },
      // position: 'topleft',
      // show: false,
      // addWaypoints: false,
      // showAlternatives: false,
      roundingSensitivity: 1,
      routeWhileDragging: true,
      draggableWaypoints: true,
      fitSelectedRoutes: true,

    }).addTo(map);

    return () => map.removeControl(routingControl);
  }, [ IamLat, IamLng, Tolat, Tolon ]);

  return null;
}

export default Routing