import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import i18n from "./translation"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Redux
import { Provider } from 'react-redux'
import { store } from './redux/store'


import Header from "./components/Header/Header"
import Footer from "./components/Footer"


import AutoScrollToTop from './components/AutoScrollToTop'
import ScrollToTop from '../src/components/ScrollToTop'                     


// const Home = lazy(() => import('./pages/Home/Home'))
import Home from './pages/Home/Home'
import Gsis from './pages/Gsis/Gsis'
import GsisPlanned from './pages/GsisPlanned/GsisPlanned'
import RightsAndLegislationPage from './pages/RightsAndLegislationPage/RightsAndLegislationPage'
import GoodPracticesPage from './pages/GoodPracticesPage/GoodPracticesPage'
import ProjectPage from './pages/Project/Project'
import PartnersPage from './pages/Partners/Partners'


import CommonPage from './pages/CommonPage/CommonPage'
import RightsAndLegislationPageOne from './pages/CommonPage/RightsAndLegislation/RightsAndLegislationPageOne'
import GoodPracticesPageOne from './pages/CommonPage/GoodPractices/GoodPracticesPageOne'


import LoginPage from './pages/Login/Login'
import MyAccountPage from './components/Login/MyAccount'


import PageNotFound from './pages/404/404'


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AutoScrollToTop/>
            <Header />
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="gsis" element={<Gsis />} />
              <Route path="gsis_planned" element={<GsisPlanned />} />
             
              
              <Route path="/rights_and_legislation" element={<RightsAndLegislationPage />} />
              <Route path="/good_practices" element={<GoodPracticesPage />} />
              <Route path="/:type/:page" element={<CommonPage />} />
              {/* <Route path="/rights_and_legislation/:page" element={<RightsAndLegislationPageOne />} />
              <Route path="/good_practices/:page" element={<GoodPracticesPageOne />} /> */}
              
              


              <Route path="/project" element={<ProjectPage />} /> 
              <Route path="/partners" element={<PartnersPage />} /> 
              

              <Route path="/login" element={<LoginPage />} />
              <Route path="/account" element={<MyAccountPage />} />
              <Route path="*" element={<PageNotFound />} />

              
              {/* <Route path="*" element={<Navigate to="/" />} /> */}
            </Routes>

            <Footer />
            <ScrollToTop />
  
          </I18nextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>  
  </>
)