
import ky from 'ky'
import { API } from './configuration'



export const axiosPage = {

  // async getPage(language: string, page: string) {

  //   const { data } = await axios.get(`${language}/pages/${page}`, {
  //     headers: {
  //       'API-KEY': X_API_KEY,
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
  //     }
  //   })

  //   return data
  // },


  async getRightsAndLegislations() {
    const data: any = await ky.get(`${API}/rights`).json()
    return data;
  },

  async getRightsAndLegislation(id: string) {
    const data: any = await ky.get(`${API}/rights/${id}`).json()
    return data;
  },


  async getGoodPractices() {
    const data: any = await ky.get(`${API}/good_practices`).json()
    return data;
  },

  async getGoodPractice(id: string) {
    const data: any = await ky.get(`${API}/good_practices/${id}`).json()
    return data;
  },
}


