import { useEffect } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import point from "../../img/point.svg"
import i_am from "../../img/i_am.svg"


L.Marker.prototype.options.icon = L.icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [0,0]
});


const Routing = ({ IamLat, IamLng, Tolat, Tolon, index }) => {

  const map = useMap();

  // function delay(time) {
  //   return new Promise(resolve => setTimeout(resolve, time));
  // }

  // console.log(IamLat, IamLng, Tolat, Tolon)

  const colors = ['green', 'red', 'orange', 'blue', 'purple', 'pink', 'black']

  useEffect(() => {

    if (!map) return

    const routingControl = L.Routing.control({

      waypoints: [ L.latLng(IamLat, IamLng), L.latLng(Tolat, Tolon)],
      // routeWhileDragging: true,
      lineOptions: {
        styles: [{ color: colors[index], weight: 4 }]
      },
      routeWhileDragging: false,
      
      // roundingSensitivity: 1,
      // routeWhileDragging: true,
      // draggableWaypoints: true,
      // fitSelectedRoutes: true,
    }).addTo(map)

    let routingControlContainer = routingControl.getContainer();
    let controlContainerParent = routingControlContainer.parentNode;
    controlContainerParent.removeChild(routingControlContainer);
    // let itineraryDiv = document.getElementById('der');
    // itineraryDiv.appendChild(routingControlContainer);
    
    // routingControlContainer.removeAttribute('class', 'leaflet-routing-container');

    return () => map.removeControl(routingControl);

  }, [ IamLat, IamLng, Tolat, Tolon ]);

  return null;
}

export default Routing