export const API = `${process.env.REACT_APP_URL_CMS}/api`



export const Header = {
  'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
}

export const HeaderForUser = { 'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'}
