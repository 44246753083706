import React from "react"
import StayTunedforTrips from './parts/StayTunedforTrips'
import StayTunedforTripsBtm from './parts/StayTunedforTripsBtm'

function Footer() {

  return (
    <>
      {/* <StayTunedforTrips />  */}
      <StayTunedforTripsBtm />
    </>
  )
}

export default Footer