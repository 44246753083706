import React, {useState, useEffect} from "react"
import { Row, Col, Carousel, Modal, Rate, Tabs, Checkbox, Button } from 'antd'
import css from './modal.module.css'
import { settingsCarouselModal } from '../../components/ArrowsForCarousel/Categories'
import ReactPlayer from 'react-player'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import ModalLiefletComponent from './ModalLiefletComponent'
import Spinner from '../../components/PageComponents/Spinner'
import SvgPointer from "../../img/LiefletSvgPointers"
import type { TabsProps } from 'antd';

// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'



import {
  BankOutlined,
  ClockCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  CheckOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  PrinterOutlined
} from '@ant-design/icons'

const TabPane = Tabs.TabPane;
interface Props{
  item: any
  openModalBoolean: boolean
  setOpenModalBoolean: (e: boolean) => void
  getSelectedText: (e: any) => void
}

const ModalComponent: React.FC<Props> = ({ item, openModalBoolean, setOpenModalBoolean, getSelectedText }:Props ) => {
  
  const [hideLine, setHideLine] = useState<boolean>(false);

  // set content margin to be 20px
  const MARGIN = 20;


  function delay(time:any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  const [loadings, setLoadings] = useState<boolean[]>([]);
  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 5000);
  };


  const createScreenshotPDF = () => {

    enterLoading(2)

    setHideLine(true)
    setTimeout(() => {
       setHideLine(false)
    }, 1000);


    const content = document.getElementById('content');
    const width = content ? content.offsetWidth : 10;
    const height = content ? content.offsetHeight : 10;
    

    if (content !== null && content !== undefined) {
      delay(500).then(() =>
        html2canvas(content, {
        scrollX: 10, // scroll to X origin
        scrollY: 10, // scroll to Y origin
        scale: 5, // 5 is quality scale
        useCORS: true,
        removeContainer: false,

      }).then(function (canvas) {
      
        var img = canvas.toDataURL('image/jpeg', 10);
       
        new jsPDF({
        orientation: 'l',
        unit: 'px', 
        format: [width + MARGIN, height + MARGIN],
        }).addImage(img, 'JPEG', MARGIN, MARGIN, width, height).save('printModal.pdf')
      }
  
      ))
    };
    
  };


  if (!openModalBoolean ) {
    return <Spinner /> 
  }

  return (
    <>
      <Modal
        title={<Row>
                  <Col xs={24} sm={24} md={2} lg={2}>
                    <Button
                      icon={<PrinterOutlined />}
                      className={css.print}
                      onClick={createScreenshotPDF}
                      loading={loadings[2]} >
                      Print
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={20} lg={20} onMouseUp={getSelectedText}>
                  { !hideLine && <div className={css.h1}>{item?.title}</div> }
                  </Col>
                </Row>
                }
        centered
        open={openModalBoolean}
        onOk={() => setOpenModalBoolean(false)}
        onCancel={() => setOpenModalBoolean(false)}
        width={'100%'}
        okText='Close'
        okButtonProps={{ style: { fontSize: '22px', height: '50px' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
     <div id="content" >
        
        {hideLine &&
          <div className={css.h2} >
            {item?.title} 
          </div>
        }
   

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            
              
          { item && 
 
          // <Tabs defaultActiveKey="1" items={items}  />
            <Tabs defaultActiveKey="1" >
              <TabPane key="1" tab={<span className={css.tab} onMouseUp={getSelectedText} > Basic Information </span>} >

                <Row className={css.MyRowHieght}>
                  <Col xs={24} sm={24} md={16} lg={10}>
                    <div>

                      {item?.images.length !== 0 &&

                        <Carousel {...settingsCarouselModal}>
                          {
                            item?.images.map((image: any, index: number, row: any) => {
                              return (
                                <div key={index}>
                                  {/* row.length !== index + 1 ? */}
                                  {image.description !== 'Video' ?
                                    <img src={image.url} alt={image.description} className={css.modalImageBox} />
                                    : <div className={css.video}><ReactPlayer controls={true} url={image.url} /></div>
                                  }
                                </div>
                              )
                            })
                          }
                        </Carousel>
                      }
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>

                    <div className={css.itemDataModal}>

                      <div className={css.iconModal}><BankOutlined style={{ fontSize: '24px' }} /> </div>
                      <div className={css.typeModal} onMouseUp={getSelectedText}>Category:<br /><span>{item?.category}</span></div>

                      {item?.schedule !== '\n ' &&
                        <>
                          <div className={css.icon2Modal}><ClockCircleOutlined style={{ fontSize: '24px' }} /> </div>
                          <div className={css.type2Modal} onMouseUp={getSelectedText}>Schedule:<br /> {item && <div style={{ fontWeight: '700' }} dangerouslySetInnerHTML={{ __html: item?.schedule }} ></div>}</div>
                        </>
                      }
                      {item?.address &&
                        <>
                          <p></p>
                          <div className={css.iconModal}><EnvironmentOutlined style={{ fontSize: '24px' }} /> </div>
                          <div className={css.typeModal} onMouseUp={getSelectedText}><span>{item?.address}</span></div>
                        </>
                      }
                      {item?.email &&
                        <>
                          <div className={css.iconModal}><MailOutlined style={{ fontSize: '24px' }} /> </div>
                          <div className={css.typeModal} onMouseUp={getSelectedText}><span>{item?.email}</span></div>
                        </>
                      }
                      {item?.telephone &&
                        <>
                          <div className={css.iconModal}><PhoneOutlined style={{ fontSize: '24px' }} /> </div>
                          <div className={css.typeModal} onMouseUp={getSelectedText}><span>{item?.telephone}</span></div>
                        </>
                      }
                      {item?.website &&
                        <>
                          <div className={css.iconModal}><GlobalOutlined style={{ fontSize: '24px' }} /> </div>
                          <div className={css.typeModal} onMouseUp={getSelectedText}><span><a href={item?.website} target="_blank" className={css.websiteModal}>{item?.website}</a></span></div>
                        </>
                      }

                      {(item?.facebook || item?.twitter || item?.instagram || item?.youtube) &&
                        <>
                          <div className={css.iconModal}></div>
                          <div className={css.typeModal} onMouseUp={getSelectedText}>Social Media<br />
                            {item?.facebook &&
                              <a href={item?.facebook} target="_blank">
                                <span className={css.socialModal}><FacebookOutlined style={{ fontSize: '26px', paddingLeft: '0px' }} /></span>
                              </a>}
                            {item?.twitter &&
                              <a href={item?.twitter} target="_blank">
                                <span className={css.socialModal}><TwitterOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                              </a>
                            }
                            {item?.instagram &&
                              <a href={item?.instagram} target="_blank">
                                <span className={css.socialModal}><InstagramOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                              </a>
                            }
                            {item?.youtube &&
                              <a href={item?.youtube} target="_blank">
                                <span className={css.socialModal}><YoutubeOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                              </a>
                            }
                          </div>
                        </>
                      }

                      </div>

                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4}>
                        <div className={css.itemAccessibilityTextModal} onMouseUp={getSelectedText}>Accessibility</div>
                        <div className={css.itemRateModal}><Rate disabled value={item?.hearing} />&nbsp;&nbsp;&nbsp;&nbsp; Hearing impairment</div>
                        <div className={css.itemRateModal}><Rate disabled value={item?.visual} />&nbsp;&nbsp;&nbsp;&nbsp; Visual impairment</div>
                        <div className={css.itemRateModal}><Rate disabled value={item?.physical} />&nbsp;&nbsp;&nbsp;&nbsp; Physical impairment</div> 
                      </Col>
                    
                    <div
                      onMouseUp={getSelectedText}
                      className={css.description2}
                      dangerouslySetInnerHTML={{ __html: item?.description || '' }} >
                    </div> 

                </Row>
  

              </TabPane>

              <TabPane key="2" tab={<span className={css.tab} onMouseUp={getSelectedText}> Accessibility facilities </span>} >
                <div className={css.description}>
                  <Row className={css.MyRowHieghtAccessibility}>
                    <Col xs={24} sm={24} md={6} lg={6} >
                      <div className={css.checkBoxTitle}>General</div>
                      {item && item.accessibility_facilities[0].map((facility: any, index: number) => {
                        return (
                          <div key={index} className={facility.is_supported ? css.checkBoxGreen : css.checkBoxRed}>
                            {facility.is_supported ? <CheckOutlined /> : <CloseOutlined />}&nbsp;&nbsp;{facility.title}
                          </div>
                        )
                      })}
                    </Col>
                    
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <div className={css.checkBoxTitle}>Hearing impairment</div>
                      {item && item.accessibility_facilities[2].map((facility: any, index: number) => {
                        return (
                          <div key={index} className={facility.is_supported ? css.checkBoxGreen : css.checkBoxRed}>
                            {facility.is_supported ? <CheckOutlined /> : <CloseOutlined />}&nbsp;&nbsp;{facility.title}
                          </div>
                        )
                      })}
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6}>
                      <div className={css.checkBoxTitle}>Visual impairment</div> 
                      {item && item.accessibility_facilities[1].map((facility: any, index: number) => {
                        return (
                          <div key={index} className={facility.is_supported ? css.checkBoxGreen : css.checkBoxRed}>
                            {facility.is_supported ? <CheckOutlined /> : <CloseOutlined />}&nbsp;&nbsp;{facility.title}
                          </div>
                        )
                      })}
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6}>
                      <div className={css.checkBoxTitle}>Physical impairment</div>
                      {item && item.accessibility_facilities[3].map((facility: any, index: number) => {
                        return (
                          <div key={index} className={facility.is_supported ? css.checkBoxGreen : css.checkBoxRed}>
                            {facility.is_supported ? <CheckOutlined /> : <CloseOutlined />}&nbsp;&nbsp;{facility.title}
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </div>
              </TabPane>
              
              <TabPane key="3" tab={<span className={css.tab} onMouseUp={getSelectedText}> Map </span>} >

                <Row justify="center" className={css.MyRowHieghtMap}>
                  <Col span={24}>
                   <ModalLiefletComponent Point={item} />    
                  </Col>
                </Row>

              </TabPane>
                
            </Tabs>
          } 
          </Col>
        </Row>
        </div>
      </Modal>
    
    </>
  )
}

export default ModalComponent