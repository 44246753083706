import React, { useState, useEffect } from "react"
import { Row, Col } from 'antd'
import HelmetComponent from '../../components/Helmet'
import classes from "./styles.module.css"
import { useFavoritePoints } from '../../hooks/useMap'
import { PointsProps } from "../../services/props"
import ModalComponent from '../../pages/Gsis/Modal'
import useSpeech from "use-speech18"
import Spinner from '../../components/PageComponents/Spinner'


const MyAccountPage: React.FC = () => {


    const [itemPoi, setItemPoi] = useState<any>()
    const [openModal, setOpenModal] = useState<boolean>(false)


    const { isLoading, isFetching, Points } = useFavoritePoints()


    //////////////////////////////////////////      Speech Voices     ///////////////////////////////////////////
    const { Speak, speak_utils } = useSpeech()
    const [isSpeech, setSpeech] = useState<boolean>(true)


    const textToSpeech = (title: string) => { isSpeech && Speak(title) }

    function getSelectedText() {
        isSpeech && Speak(window?.getSelection()?.toString() || '')
    }

    useEffect(()=> {
        // console.log("array of avalaible voices", speak_utils.Voices)
        speak_utils.setVoice(speak_utils.Voices[2].name)
        // console.log(speak_utils.selectedVoice, "currently selected voice")   
    }, [speak_utils.Voices])


    const onChangeSpeech = (checked: boolean) => {
        setSpeech(checked)
    };

    //////////////////////////////////////////      end Speech Voices     ///////////////////////////////////////////
    
    
    if (isLoading || isFetching) {
        return <Spinner /> 
    }
    

    return (
    <>
    <HelmetComponent title={`${process.env.REACT_APP_WEB_SITE_NAME} | My Account`} descrition={`${process.env.REACT_APP_WEB_SITE_NAME} | My Account`} />
        
    <div className={classes.backgroundImage}>
        <div className={ classes.containerGp}>
            <Row justify="center">
                <Col xs={{span:22}} lg={{span : 10}}>
                    <div className={classes.MyAccountTitle}> My Account</div>
                    <div className={classes.MyAccountEmail}> Email: sergios@mail.ru</div>   
                </Col>
                        
                <Col xs={{ span: 22 }} lg={{ span: 10 }}>
                    <div className={classes.MyAccountFavorite}> Your Favorite POIs ({Points.length}):</div>
                    <div style={{ width: '100%' }}>

                        {Points.map((poi: PointsProps) => {
                            return (
                                <>
                                    <div
                                        className={classes.MyFavoritePois}
                                        onClick={() => [setItemPoi(poi), setOpenModal(true)]}>
                                        {poi.title}
                                    </div>
                                </>
                            )
                        })}

                    </div>

                </Col>

            </Row>
        </div>
            </div>
    <div style={{display:'none'}}>   
        <ModalComponent
            item={itemPoi}
            openModalBoolean={openModal}
            setOpenModalBoolean={() => setOpenModal(false)}
            getSelectedText={ getSelectedText }
            />
    </div>
    </>
    )
}

export default MyAccountPage
