class Utils {


  static MapFilter(Points, checkedItems, checkedCategoriesItems ) {
	
		let ArrayItems = []
		let ArrayIds = []
		let ListArray = []
		let check = false
		let pois = []

		if (checkedItems.length == 0 && checkedCategoriesItems.length == 7 )
		 return Points

		
		if (checkedItems.length == 0 && checkedCategoriesItems.length !== 0) {

			for (let category in checkedCategoriesItems){
				Points.map(item => (item.category === checkedCategoriesItems[category]) && ListArray.push(item))
			}
		}
			
		else {

			Points.map(point => {
					
				for (const checkedItem in checkedItems) {

					if (point.is_accessibility_facilities.includes(checkedItems[checkedItem])) {
						pois= point
						check=true
					}
		
					else {
						check = false
						return
					
					}
				}
					
				if (check) {

						if (ArrayIds.includes(pois.id))
								return;	
						ArrayItems.push(pois)
						ArrayIds.push(pois.id)
						check=false	
				}
			})


			// console.log('ArrayItems', ArrayItems)
			// console.log('checkedItems', checkedItems)

			for (let category in checkedCategoriesItems){
				ArrayItems.map(item => (item.category === checkedCategoriesItems[category]) && ListArray.push(item))
			}
		}

		// console.log('ListArray',ListArray)
		return ListArray

	}



  // static MapFilter(Points, checkedItems, checkedCategoriesItems ) {
	
	// 	let ArrayItems = []
	// 	let ArrayIds = []
	// 	let ListArray = []

	// 	if (checkedItems.length == 0 && checkedCategoriesItems.length == 0 )
	// 	 return []

	// 	for (const checkedItem in checkedItems) {

	// 		Points.map(point => {

	// 				switch (checkedItems[checkedItem]) {
	// 					case '1': if (point.visual >= 1) {
	// 						if (ArrayIds.includes(point.id))
	// 						return;
	// 						ArrayItems.push(point)
	// 						ArrayIds.push(point.id)
	// 					}
	// 					break;
						
	// 					case '2': if (point.physical >= 1) {
	// 						if (ArrayIds.includes(point.id))
	// 						return;
	// 						ArrayItems.push(point)
	// 						ArrayIds.push(point.id)
	// 					}
	// 						break;
						
	// 					case '3': if (point.hearing >= 1) {
	// 						if (ArrayIds.includes(point.id))
	// 						return;
	// 						ArrayItems.push(point)
	// 						ArrayIds.push(point.id)
	// 					}
	// 						break;
	// 				}

	// 			})
	// 	}
		
	// 	for (let category in checkedCategoriesItems){
	// 		ArrayItems.map(item => (item.category === checkedCategoriesItems[category]) && ListArray.push(item))
	// 	}

	// 	return ListArray
	// }


  // static MapFilter(Points, checkedItems ) {
	
	// 	let ArrayItems = []
	// 	let ArrayIds = []

	// 	if (checkedItems.length == 0)
	// 		return []

	// 	for (const checkedItem in checkedItems) {

	// 		Points.map(point => {
				
	// 			if (point.type.includes(checkedItems[checkedItem])) {

	// 				if (ArrayIds.includes(point.id))
	// 					return;
					
	// 				  ArrayItems.push(point)
	// 					ArrayIds.push(point.id)
	// 				}
	// 			})
	// 	}
		
	// 	return ArrayItems
	// }


}

export default Utils