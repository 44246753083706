import React from "react"
import { Row, Col  } from 'antd'
import classes from './styles.module.css'
import { ThreeDataEn } from '../Home/components/ThreeD'
import RightsAndLegislationComponent from '../../components/PageComponents/RightsAndLegislation'
import { useRightsAndLegislations } from '../../hooks/useMap'

interface Props{
  id: number
  title: string
  text: string
  link: number
}

const RightsAndLegislationPage: React.FC = () => {


  const { isLoading, isFetching, Data } = useRightsAndLegislations()


  return (

    <>
      <div className={classes.container}>
        <div className={classes.title}>Rights and legislation</div>
      </div>
      <div className={classes.container}>
        <Row justify="center" >
          {ThreeDataEn.map((data) => {
        
            return (
              <Col xs={24} sm={24} md={8} lg={8} key={data.id}>

                <RightsAndLegislationComponent
                  title={data.title}
                  text={data.text}
                  link={data.link}
                />

              </Col>
            )
          })}
        </Row>
      </div>
    </>
  )
}

export default RightsAndLegislationPage