
import React, { useState, useEffect, Suspense, useCallback, useLayoutEffect } from "react"
import { Row, Col, Button, Drawer, Space, Checkbox, Rate, Carousel, notification, Modal, Switch, Popconfirm, message } from 'antd'
import classes from './styles.module.css'
import css from './modal.module.css'
import { usePoints, useAddRemoveFavoriteHook } from '../../hooks/useMap'
import { useRefreshTokenHook } from '../../components/Login/useService'
import { useNavigate } from "react-router-dom"

import Spinner from '../../components/PageComponents/Spinner'
import utils from '../../utils'
import HelmetComponent from '../../components/Helmet'

import HeartSvg from '../../img/svg/heart'
import CheckBoxComponent from './checkBoxGroup'

import SitesMonumentsSvg from '../../img/svg_pointers/SitesMonumentsSvg'
import MuseumsSvg from '../../img/svg_pointers/MuseumsSvg'
import VariousSvg from '../../img/svg_pointers/VariousSvg'
import ReligiousSitesSvg from '../../img/svg_pointers/ReligiousSitesSvg'
import HotelsSvg from '../../img/svg_pointers/HotelsSvg'
import RestaurantsSvg from '../../img/svg_pointers/RestaurantsSvg'
import CultureSvg from '../../img/svg_pointers/CultureSvg'

import LiefletComponent from './Lieflet'
import ModalComponent from './Modal'


import {
  CheckOutlined,
  CloseOutlined,
  MenuOutlined,
  CloseCircleOutlined,
  BankOutlined,
  ClockCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  HeartOutlined,
  ExclamationOutlined
} from '@ant-design/icons'

import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { PointsProps } from "../../services/props"
import { settingsCarouselMap, settingsCarouselModal } from '../../components/ArrowsForCarousel/Categories'
import { useToken } from '../../components/Login/useService'
import ReactPlayer from 'react-player'

import useSpeech from "use-speech18"


// Redux
import { useDispatch } from 'react-redux'
import { AddToBasket } from '../../redux/reducer/basket'
import { AddToGsisChecked }  from '../../redux/reducer/gsisChecked'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'


const Gsis: React.FC = () => {
  const [messageApi, errorMessage] = message.useMessage()
  const [zoom, setZoom] = useState<number>(9)
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const basketArray: any[] = useSelector((state: RootState) => state.Basket.Basket)


  const url = new URL(`${process.env.REACT_APP_URL_CMS}/oauth/authorize?response_type=code&client_id=itNlXJDZ1RFzcen0zLIyi5q780J5gwUx&client_secret=qPvAZqlVFYHTyKU1xIGjSxB3jUTMcLU7&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=dotsoft&embedded=true`);
  
  const [modal, contextHolderModal] = Modal.useModal()

  const [api, contextHolder] = notification.useNotification()

  const [ isLogged, setIsLogged] = useState<boolean>(false)
  const { isLoading, isFetching, Points, Categories, FacilityArray, CategoryArray, refetchPoints } = usePoints()

  // console.log(Points)

  // window.localStorage.getItem("access_token") === null ? console.log('Not Logged', Points) : console.log('Logged', Points)
  // console.log('Points', Points)
  // console.log('PointsLogged', Points)
  
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);


  const [items, setItems] = useState<PointsProps[]>([])
  const [item, setItem] = useState<PointsProps>()

  const [toLat, setTolat] = useState<any>('')
  const [toLon, setTolon] = useState<any>('' || undefined)
  
  const [checkedItems, setCheckedItems] = useState<CheckboxValueType[]>([])
  const [checkedCategoriesItems, setCheckedCategoriesItems] = useState<CheckboxValueType[]>(['Museums', 'Various', 'Religious sites', 'Hotels', 'Restaurants', 'Sites & Monuments', 'Culture & Arts'])
  const [isDisabledAddToBasketButton, setIsDisabledAddToBasketButton] = useState<boolean>(false)

  const [myColapFacility, setMyCollapseFacility] = useState<string>('1')
  // setTimeout(() => { setMyCollapseFacility('')}, 200);

  // console.log('Points', Points)
  // console.log('FacilityArray', FacilityArray)
    
  useEffect(() => {
    setItems(Points)
  
  }, [Points])


  useEffect(() => {
    refetchPoints()
    // console.log('fsd2')
  }, [isLogged])



  useLayoutEffect(() => {
    let result = utils.MapFilter(Points, checkedItems, checkedCategoriesItems)
// console.log('fsd')
    dispatch(AddToGsisChecked(checkedItems))
    // console.log(checkedItems)
    setItems(result)
  }, [checkedItems, checkedCategoriesItems])


  
  const showDrawer = () => { setOpen(true); setShowFilter(false); setTimeout(() => { setMyCollapseFacility('')}, 2);   }
  const onClose = () => { setOpen(false); setTimeout(() => { setShowFilter(true) }, 50)  }
  const onClose2 = () => { setOpen2(false); setTimeout(() => { setShowFilter(true) }, 50); setZoom(9); setOpen(false);}



  const PointId = (id: number) => {

    const item = Points.filter( (item:any) => item.id === id)
    setItem(item[0])
    setOpen2(true)
    setShowFilter(false)
    setZoom(16)
  }





  //////////////////////////////////////////      Speech Voices     ///////////////////////////////////////////
  const { Speak, speak_utils } = useSpeech()
  const [isSpeech, setSpeech] = useState<boolean>(true)


  const textToSpeech = (title: string) => { isSpeech && Speak(title) }

  function getSelectedText() {
    isSpeech && Speak(window?.getSelection()?.toString() || '')
  }

  useEffect(()=> {
    // console.log("array of avalaible voices", speak_utils.Voices)
    speak_utils.setVoice(speak_utils.Voices[2].name)
    // console.log(speak_utils.selectedVoice, "currently selected voice")   
  }, [speak_utils.Voices])


  const onChangeSpeech = (checked: boolean) => {
    setSpeech(checked)
  };

 //////////////////////////////////////////      end Speech Voices     ///////////////////////////////////////////


  
  
  
  
 //////////////////////////////////////////      Open Message Window     ///////////////////////////////////////////

  const error = () => {
    
    setIsDisabledAddToBasketButton(true)

    messageApi.open({
      type: 'error',
      content: 'This location has already been added to your favorites',
      style: {
        marginTop: '20vh',
      },
    });

    setTimeout(() => {
      setIsDisabledAddToBasketButton(false)
    }, 2000);

  };
 //////////////////////////////////////////      End Message Window     ///////////////////////////////////////////


  const setTolatAndLon = (lat: any, lon: any) => {
    setTolat(lat)
    setTolon(lon)
  }


  if ("geolocation" in navigator) {
  } else {
      api.info({
        message: `Open Gps`,
        description:'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
  }










  const [codeParam, setCodeParam] = useState<any>(null)
  const [toggle, setToggle] = useState<boolean>(false)
  const [idAddRemoveFavorite, setIdAddRemoveFavorite] = useState<number>(0)
  const [basketPoi, setBasketPoi] = useState<PointsProps>()
  const { Token, refetch } = useToken( codeParam )
  const { refetchAddRemoveFavorite } = useAddRemoveFavoriteHook(idAddRemoveFavorite)
  const { refetchRefreshTokenHook } = useRefreshTokenHook()
//   const [checkIfLogged, setCheckIfLogged] = useState<boolean>(false)
// console.log(checkIfLogged)
  
  
  useEffect(() => {
    const codeParam = new URLSearchParams(window.location.search).get('code')
    setCodeParam(codeParam)
    
    if (codeParam){
      window.localStorage.setItem("codeParam", codeParam)
      setIsLogged(true)
      
    }
  }, [])


  useEffect(() => {
    if (codeParam !== null) {
      refetch();
      setIsLogged(true)
    }
  }, [codeParam])






  const addFavorite = (id: number) => {

    if (window.localStorage.getItem("access_token") !== null){

      if (id !== 0) {
        setToggle(!toggle)
        setIdAddRemoveFavorite(id)

        setTimeout(() => {
          refetchAddRemoveFavorite()
          refetchRefreshTokenHook()
        }, 1000);
        
        let objIndex = Points.findIndex((item => item.id == id))
        Points[objIndex].favorite = Points[objIndex].favorite === true ? false : true
      }

    } 

    else {
      console.log('Please Login or Register')
    }

  }





  const addToBasket = (item: any) => {

    basketArray.indexOf(item) === -1 ?
      dispatch(AddToBasket(item))
      :
      error()
  }


  // useEffect(() => {
    
  //   if (idAddRemoveFavorite !== 0) {
  //     refetchAddRemoveFavorite()
  //     refetchRefreshTokenHook()
   
  //     let objIndex = Points.findIndex((item => item.id == idAddRemoveFavorite))
  //     Points[objIndex].favorite = Points[objIndex].favorite === 1 ? 2 : 1


  //   }
  // }, [idAddRemoveFavorite])

  
  
  // useEffect(() => {
  //   // if (codeParam !== null) {
  //   refetch()
  //   console.log('call refetch')
  //   // }
  // }, [])



  // const HeartChangeColor = (favorite: any | 0) => {
  //   console.log(favorite)
  //   return (
  //     <span
  //       className={classes.heart}
  //       // onClick={() => setIdAddRemoveFavorite(item?.id || 0)}>
  //       onClick={()=> addFavorite(item?.id || 0) }>
  //       <HeartSvg width={36} height={36} fill={favorite === 1 ? 'white' : 'red'}  />
  //     </span>
  //   )
  // }

  // console.log('isLoading', isLoading)

  // if (isLoading || isFetching) {
  //   return <Spinner /> 
  // }

  if ( Points.length == 0 ) {
    
    return <>
      
      {/* <Spinner /> */}
      
            <LiefletComponent
              Points={items}
              IdPoint={(e: number) => PointId(e)}
              sendTextToSpeech={(e: string) => textToSpeech(e)}
              Tolat={toLat}
              Tolon={toLon}
              zoom={zoom}
            />
          </> 
  }


  return (
    <>
      {contextHolder}
      {contextHolderModal}
      {errorMessage}

      <HelmetComponent title='Alter Trip | Gsis' descrition='Alter Trip' />
      
      {showFilter &&
        <Button
          icon={<MenuOutlined />}
          onClick={showDrawer}
          className={classes.btnFilters}>FILTERS
        </Button>
      }

      <Switch
        onChange={onChangeSpeech}
        checkedChildren="Speech Enabled"
        unCheckedChildren="Speech Disabled"
        defaultChecked
        className={classes.btnSpeech} />
      


      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>

          <LiefletComponent
            Points={items}
            IdPoint={(e: number) => PointId(e)}
            sendTextToSpeech={(e: string) => textToSpeech(e)}
            Tolat={toLat}
            Tolon={toLon}
            zoom={zoom}
          />

          <div style={{ opacity: '0.999' }}>
           
            
            <Drawer
              bodyStyle={{ padding: '0px' , backgroundColor: '#5BAEB2' }}
              autoFocus={false}
              mask={false}
              title='Disability Filters'
              placement={'left'}
              closable={false}
              onClose={onClose}
              open={open}
              getContainer={false}
              width={280}
              extra={
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={onClose}
                    type="text"
                    style={{ position: 'absolute', top: 8, right: 10,  color: 'white' }}
                  />
                </Space>
              }
            >
              <div style={{ backgroundColor: '#864f8b' }}>
                

              <div className={classes.category1}>
                <hr style={{ width: '250px' }}/>
               
                  <CheckBoxComponent
                    // checkedId={checkedId}
                    FacilityArray={ FacilityArray }
                    array={Points && Points[0].accessibility_facilities}
                    collapse={myColapFacility}
                    CheckBoxChecked={(e)=> setCheckedItems(e)}
                  />

                <br/>
              </div>


              {/* <div className={classes.category1}>
                <hr style={{ width: '230px' }}/>
                <Checkbox.Group style={{ width: '100%' }} defaultValue={['1', '2', '3']} onChange={(checkedValues: CheckboxValueType[])=> setCheckedItems(checkedValues)}>
                  <Row justify='start'>
                    <Col span={24}>
                      <p className={classes.checkboxPadding}>
                        <Checkbox value="1">
                          <span className={classes.checkBoxSvg}>
                            <VisualSvg width={46} height={46} />
                          </span>
                          <span className={classes.checkboxItem}>Visual impairment</span> 
                        </Checkbox>
                      </p>
                    </Col>
                    <Col span={24}>
                      <p className={classes.checkboxPadding}>
                        <Checkbox value="2">
                          <span className={classes.checkBoxSvg}>
                            <LimitedMobilitySvg width={46} height={46} />
                          </span>   
                          <span className={classes.checkboxItem}> Hearing impairment</span>
                        </Checkbox>
                      </p>
                    </Col>
                    <Col span={24}>
                      <p className={classes.checkboxPadding}>
                        <Checkbox value="3">  
                          <span className={classes.checkBoxSvg}>
                            <HearingSvg width={46} height={46} />
                          </span>    
                          <span className={classes.checkboxItem}>Physical impairment</span>
                        </Checkbox>
                      </p>
                    </Col>
                  </Row>
                  </Checkbox.Group>
                  <br/> <br/>
                </div> */}

                <div style={{ backgroundColor: '#5BAEB2' }}>
                <div className={classes.category2}>
                  <div className={classes.category2}>Category Filters</div>
                    <hr style={{ width: '230px' }} />
                    <Checkbox.Group
                      style={{ width: '100%' }}
                      defaultValue={[...CategoryArray]}
                      onChange={(checkedValues: CheckboxValueType[]) => setCheckedCategoriesItems(checkedValues)}>
                    <Row justify='start'>

                      {/* { Categories.map((category: any) => {
                      console.log(category.title)
                      return (
                        <Col key={category.id} span={24}>
                          <p className={classes.checkboxPadding}>
                            <Checkbox value={category.title}>
                              {category.title}
                            </Checkbox>
                          </p>
                        </Col>
                        )
                      })
                    } */}

                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Sites & Monuments'>
                            <span className={classes.checkBoxSvg}>
                              <SitesMonumentsSvg width={46} height={46} />
                            </span>
                            <span className={classes.checkboxItem}>Sites & Monuments</span>
                          </Checkbox>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Hotels'>  
                            <span className={classes.checkBoxSvg}>
                              <HotelsSvg width={46} height={46} />
                            </span>   
                            <span className={classes.checkboxItem}>Hotels</span>
                          </Checkbox>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Restaurants, Cafes & Bars'>
                            <span className={classes.checkBoxSvg}>
                              <RestaurantsSvg width={46} height={46} />
                            </span> 
                            <span className={classes.checkboxItem}>Restaurants, Cafes</span>
                          </Checkbox>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Museums'>
                            <span className={classes.checkBoxSvg}>
                              <MuseumsSvg width={46} height={46} />
                            </span>
                            <span className={classes.checkboxItem}>Museums</span>
                          </Checkbox>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Religious sites'>
                            <span className={classes.checkBoxSvg}>
                              <ReligiousSitesSvg width={46} height={46} />
                            </span>
                            <span className={classes.checkboxItem}>Religious sites</span>
                          </Checkbox>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Various'> 
                            <span className={classes.checkBoxSvg}>
                              <VariousSvg width={46} height={46} />
                            </span>
                            <span className={classes.checkboxItem}>Various</span>
                          </Checkbox>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className={classes.checkboxPadding}>
                          <Checkbox value='Culture & Arts'> 
                            <span className={classes.checkBoxSvg}>
                              <CultureSvg width={46} height={46} />
                            </span>
                            <span className={classes.checkboxItem}>Culture & Arts</span>
                          </Checkbox>
                        </p>
                      </Col>  

                    </Row>
                    </Checkbox.Group>
                   
                  </div>
                </div>
              </div>
            </Drawer>

            

            <Drawer
              drawerStyle={{ background: '#0c4081', opacity: '1' }}
              closeIcon={<CloseCircleOutlined style={{color: 'white', fontSize: '23px'}} />}
              autoFocus={false}
              mask={false}
              title={
                <div>
                  <div className={classes.itemTitle}>{item?.title}</div>
                  
                    {window.localStorage.getItem("access_token") === null ?
                      
                      <div className={classes.heart}>
                        <Popconfirm
                          placement="topRight"
                          title={<div style={{ width: '200px', textAlign: 'left', fontSize: '14px', color: '#05324c', lineHeight: '1.2' }}>You have to be {<a href={url.href}>logged in</a>} for adding a location as favorite.</div>}
                          icon={<ExclamationOutlined style={{ color: 'red', fontWeight: '600', fontSize: '26px' }} />}
                          okText={'Close'}
                          showCancel={false}
                        >
                          <div>
                            <HeartSvg width={36} height={36} fill={item?.favorite === false ? 'white' : 'red'} />
                          </div>
                        </Popconfirm>
                      </div>
                      : 
                      <div className={classes.heart}
                          // onClick={() => setIdAddRemoveFavorite(item?.id || 0)}>
                          // <HeartChangeColor favorite={item?.favorite || 0} /> 
                        onClick={() => addFavorite(item?.id || 0)}>
                          <HeartSvg width={36} height={36} fill={item?.favorite === false ? 'white' : 'red'} />
                      </div>
                    }
                 
                </div>
              }
              placement={'right'}
              onClose={onClose2}
              open={open2}
              getContainer={false}
              width={380}
            >
              <div className={classes.checkBoxesContainer}>

                <div>
                  <Button
                    size={'large'}
                    className={classes.StartHereBtn}
                    onClick={()=> setTolatAndLon(item?.lat, item?.lon)  }
                  >GET DIRECTIONS
                  </Button>
                </div>
                <div>
                  <Button
                    size={'large'}
                    className={classes.StartHereBtn}
                    onClick={() => setOpenModal(true)}
                  >Show More
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={ isDisabledAddToBasketButton }
                    size={'large'}
                    className={classes.StartHereBtn}
                    onClick={() => addToBasket(item)}
                  >Add to Trip Planner
                  </Button>
                </div>
                {item?.images && item?.images?.length > 0 &&
    
                  <div style={{paddingBottom: '20px'}}>
                    <Carousel {...settingsCarouselMap}>
                      {
                        item?.images.map((image: any, index: number, row: any) => {
                          return (
                            <div key={index}>    
                              { image.description !== 'Video' ?
                                <div className={classes.helper} ><img src={image.url} alt={image.description}  /></div>
                                : <div className={css.videoSmall}><ReactPlayer controls={true} url={image.url} width={'300px'} height={'160px'} style={{marginLeft: '10px', marginTop: '12px'}} /></div>
                              }
                            </div>
                          )
                        })
                      }
                    </Carousel>
                  </div>
                }

                <div className={classes.itemData}>

                  <div className={classes.icon}><BankOutlined style={{fontSize: '24px'}} /> </div>
                  <div className={classes.type} onMouseUp={getSelectedText}>Category:<br/><span>{item?.category}</span></div>
               
                  {item?.schedule !== '\n ' &&
                    <>
                      <div className={classes.icon2}><ClockCircleOutlined style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type2} onMouseUp={getSelectedText}>Schedule:<br /> {item && <div style={{fontWeight: '700'}} dangerouslySetInnerHTML={{ __html: item?.schedule }} ></div> }</div>
                    </>
                  }
                  {item?.address &&
                    <>
                      <div className={classes.icon}><EnvironmentOutlined style={{fontSize: '24px'}} /> </div>
                      <div className={classes.typeAdress} onMouseUp={getSelectedText}>Address:<br /><span>{item?.address}</span></div>
                    </>
                  }
                  {item?.email &&
                    <>
                      <div className={classes.icon}><MailOutlined  style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Email:<br /><span>{item?.email}</span></div>
                    </>
                  }
                  {item?.telephone &&
                    <>
                      <div className={classes.icon}><PhoneOutlined  style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Telephone:<br /><span>{item?.telephone}</span></div>
                    </>
                  }
                  {item?.website &&
                    <>
                      <div className={classes.icon}><GlobalOutlined  style={{fontSize: '24px'}} /> </div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Website:<br /><span><a href={ item?.website } target="_blank" className={classes.website}>{item?.website}</a></span></div>
                    </>
                  }

                  { (item?.facebook || item?.twitter || item?.instagram || item?.youtube) &&
                    <>
                      <div className={classes.icon}></div>
                      <div className={classes.type} onMouseUp={getSelectedText}>Social Media<br />
                      {item?.facebook &&
                        <a href={ item?.facebook } target="_blank">
                          <span className={classes.social}><FacebookOutlined style={{ fontSize: '26px', paddingLeft: '0px' }} /></span>
                        </a>}
                      {item?.twitter &&
                        <a href={ item?.twitter } target="_blank">
                          <span className={classes.social}><TwitterOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                        </a>
                      }
                      {item?.instagram &&
                        <a href={ item?.instagram } target="_blank">
                          <span className={classes.social}><InstagramOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                        </a>
                      }
                      {item?.youtube &&
                        <a href={ item?.youtube } target="_blank">
                          <span className={classes.social}><YoutubeOutlined style={{ fontSize: '26px', paddingLeft: '20px' }} /></span>
                        </a>
                      }
                     </div>
                    </>
                  }
                  <div className={classes.icon3}></div>
                  <div className={classes.type3}></div>
                  
                </div>

                <hr/>
                <div className={classes.itemAccessibilityText} onMouseUp ={getSelectedText}>Accessibility</div>

                <div className={classes.itemRate} ><Rate disabled value={item?.visual} />&nbsp;&nbsp;&nbsp;&nbsp;<span onMouseUp={getSelectedText}> Visual impairment</span></div>
                <div className={classes.itemRate} ><Rate disabled value={item?.hearing} />&nbsp;&nbsp;&nbsp;&nbsp;<span onMouseUp={getSelectedText}> Hearing impairment</span></div>
                <div className={classes.itemRate} ><Rate disabled value={item?.physical} />&nbsp;&nbsp;&nbsp;&nbsp;<span onMouseUp={getSelectedText}> Physical impairment</span></div>

              </div>
            </Drawer>

          </div>

        </Col>
      </Row>


      <div style={{display: 'none'}}>
      <ModalComponent
        item={item}
        openModalBoolean={openModal}
        setOpenModalBoolean={() => setOpenModal(false)}
        getSelectedText={ getSelectedText }
      />
</div>
    </>
  )
}

export default Gsis
