import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LoginArray{ value: number }
interface LoginState { Login: LoginArray[] }
const initialState: LoginState = { Login: [] }

export const LoginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    Login: (state, action) => {
      state.Login.splice(0, state.Login.length);
      state.Login.push(action.payload)
    },
    // Municipality: (state, action) => {
    //   state.Municipality.splice(0, state.Municipality.length);
    //   if(action.payload !== '')
    //     state.Municipality.push(action.payload)
      
    // }
  },
})

export const { Login } = LoginSlice.actions
export default LoginSlice.reducer 