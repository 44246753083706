export const RigtsAndLegislation_Protection_Of_The_Elderly = {
  
  header: 'Rights and legislation',
  text:`
  <h3>PROTECTION OF THE ELDERLY</h3>
  <div>
  Human rights apply to all, regardless of age. Despite an aging population, the vulnerability of the elderly and despite references to elderly in other international conventions, there is no international convention exclusively focusing on the rights of the elderly. The rights of the elderly are protected only under the general term “human rights”.
  </div>
  <h4>A. NATIONAL LEGAL FRAMEWORK</h4>
  <h5>A.1. INTERNATIONAL INSTRUMENTS</h5>
  <div>
  Several international instruments protect groups or individuals against the discrimination they duffer, among others, due to their age. This protection follows from the principle of non - discrimination and is based on the rights of human freedom and dignity and the principles of fair and equal treatment enshrined in these instruments.
  </div>
  <h5>A.2. CONSTITUTION – LAW</h5>
  <div>
  - The Constitution enshrines - article 21, paragraph 3 - the special care of the State for the health of citizens and adopts special measures for the protection of youth, the elderly, persons with disabilities and the care for the needy.
  </div>
  <div>
  - Act 3304/2005 enshrines the principle of equal treatment, irrespective of racial or ethnic origin, religion or belief, disability, age, or sexual orientation.
  </div>

  <h6>Elder Abuse </h6>
  <div>
  With an aging population and the important social and economic reforms that this demographic change brings about, finding ways to prevent elder abuse and ensuring a decent life in old age is a major challenge across the European Union. The measures to be implemented should fully ensure that the elderly are adequately protected and can enjoy a decent life, even when dependent on others for their care. According to a report prepared by the World Health Organization (WHO/Europe) in July 2011, each year, 2.7% (4.000.000) of all persons over 60 years’ experience physical abuse, 19.4% (29 million) experience psychological abuse, 3.8% (6.000.000) experience economic exploitation and, finally, 0.7% (1.000.000) experience sexual abuse. Regarding Greece, there is lack of data on the size of the problem, as there is no national policy for the systematic (or not) recording of incidents.
  </div>
  `
}


