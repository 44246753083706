
import React from "react"
import Props from './svgProps'

const HotelSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 50"
        fill="none">
       
        <path id="Path_2993" data-name="Path 2993" d="M18.488,0A18.488,18.488,0,0,0,0,18.488C0,28.7,18.488,46.836,18.488,46.836S36.977,28.7,36.977,18.488A18.488,18.488,0,0,0,18.488,0" transform="translate(0 0)" fill="#f7931e"/>
        <path id="Path_2994" data-name="Path 2994" d="M30.4,13.007l1.787-.251c.063,0,.094-.031.125-.094l.815-1.63c.063-.125.22-.125.251,0l.815,1.63a.427.427,0,0,0,.125.094l1.787.251a.158.158,0,0,1,.094.251l-1.285,1.254c-.031.031-.063.094-.031.125l.314,1.787a.145.145,0,0,1-.22.156l-1.6-.846a.1.1,0,0,0-.125,0l-1.6.846a.15.15,0,0,1-.22-.156l.314-1.787c0-.063,0-.094-.031-.125l-1.285-1.254C30.239,13.164,30.27,13.038,30.4,13.007Z" transform="translate(-7.409 -2.675)" fill="#fff"/>
        <path id="Path_2995" data-name="Path 2995" d="M20.685,13.007l1.787-.251c.063,0,.094-.031.125-.094l.815-1.63c.063-.125.22-.125.251,0l.815,1.63a.427.427,0,0,0,.125.094l1.787.251a.158.158,0,0,1,.094.251L25.2,14.512c-.031.031-.063.094-.031.125l.314,1.787a.145.145,0,0,1-.22.156l-1.6-.846a.1.1,0,0,0-.125,0l-1.6.846a.15.15,0,0,1-.22-.156l.314-1.787c0-.063,0-.094-.031-.125l-1.285-1.254C20.528,13.164,20.56,13.038,20.685,13.007Z" transform="translate(-5.034 -2.675)" fill="#fff"/>
        <path id="Path_2996" data-name="Path 2996" d="M18.76,25.436A2.257,2.257,0,1,1,16.5,23.179a2.248,2.248,0,0,1,2.257,2.257" transform="translate(-3.484 -5.669)" fill="#fff"/>
        <path id="Path_2997" data-name="Path 2997" d="M10.976,13.007l1.787-.251c.063,0,.094-.031.125-.094l.815-1.63c.063-.125.22-.125.251,0l.815,1.63a.427.427,0,0,0,.125.094l1.787.251a.158.158,0,0,1,.094.251L15.49,14.512c-.031.031-.063.094-.031.125l.314,1.787a.145.145,0,0,1-.22.156l-1.6-.846a.1.1,0,0,0-.125,0l-1.6.846a.15.15,0,0,1-.22-.156l.314-1.787c0-.063,0-.094-.031-.125l-1.285-1.254C10.819,13.164,10.851,13.038,10.976,13.007Z" transform="translate(-2.659 -2.675)" fill="#fff"/>
        <path id="Path_2998" data-name="Path 2998" d="M31.628,32.068a.27.27,0,0,1-.251.251H29.9a.27.27,0,0,1-.251-.251V29.905H12.444v2.163a.27.27,0,0,1-.251.251H10.72a.27.27,0,0,1-.251-.251v-11a.27.27,0,0,1,.251-.251h1.474a.27.27,0,0,1,.251.251v6.9H31.628Z" transform="translate(-2.56 -5.091)" fill="#fff"/>
        <path id="Path_2999" data-name="Path 2999" d="M34.276,27.614H21.3V24.166a1.317,1.317,0,0,1,1.568-1.285l9.31,2.006a2.653,2.653,0,0,1,2.1,2.6Z" transform="translate(-5.209 -5.59)" fill="#fff"/>
          
      </svg>
    )
}

export default HotelSvg
