import React from "react"
import { Button } from 'antd'
import classes from './styles.module.css'
import { Link } from 'react-router-dom'


interface Props{
  image: string
  title: string
  text: string
  link: string
}

const GoodPracticesComponent: React.FC<Props> = ({image, title, text, link }:Props) => {

  return (

    <div className={classes.box}>
      <div className={classes.box2}>

        <img src={image} alt={title} style={{ width: '100%' }} />
        <div className={classes.boxTitle}>{title}</div>
        <div className={classes.boxText} dangerouslySetInnerHTML={{ __html: text.slice(0, 300)+ ' ...' }} ></div>
        {/* <div className={classes.boxBtn}> */}


        <div className={classes.boxCenter}>
          {/* <Link to={`/good_practices/${link}`}> */}
          <Link to={`${link}`}>
            <Button size={'large'} className={classes.StartHereBtn}>READ MORE</Button>
          </Link>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default GoodPracticesComponent
