import axios from 'axios'


import { PointsData } from './PointsData'
import { PointsDataHome } from './PointsDataHome'
import { PointsProps } from '../services/props'

const API = `${process.env.REACT_APP_URL_CMS}/api`
const API_KEY = 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg'



export const axiosPointsDataHome = {

  // async getPoints() {
  //   const { data } = await axios.get(`${API}/mainareas`, { headers: { 
  //     'X-API-KEY': X_API_KEY
  //   }})
  //   return data.success
  // }
  async getPointsDataHome() {
    return PointsDataHome
  }
}

export const axiosGSIS = {

  async getPoints() {

    let data: any = []
    
    // console.log('Called Use Points', 'Login: ', isLogged)
    
    window.localStorage.getItem("access_token") === null ?
    
    {data} = await axios.get(`${API}/en/pois?limit=300`, {
      headers: {
        'API-KEY': API_KEY,
      }
    })
      
    :

    {data} = await axios.get(`${API}/oauth/en/pois?limit=300`, {
      headers: {
        'API-KEY': API_KEY,
        "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
      }
    })
    return data.pois 
  },


  async getPointsLogged() {
    const { data } = await axios.get(`${API}/oauth/en/pois`, {
      headers: {
        'API-KEY': API_KEY,
        "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
      }
    })
    return data.pois 
  },


  async getPoint(id: number) {

    const data = await axios.get(`${API}/en/pois/${id}`, {
      headers: {
        'API-KEY': API_KEY
      }
    })
    return data
  },


  async getCategories() {

    const { data } = await axios.get(`${API}/en/pois/categories`, {
      headers: {
        'API-KEY': API_KEY
      }
    })
    return data 
  },


  async getFacilities() {

    const { data } = await axios.get(`${API}/en/accessibility-facilities`, {
      headers: {
        'API-KEY': API_KEY
      }
    })
    return data.accessibility_facilities 
  },


  async addRemoveFavorite(id: number) {

    // await axios.post(`https://cms.alter-trip.eu/api/users/favorites/pois/${id}`, {
    //   headers: {
    //     'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //     "Content-type": "application/json",
    //     "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
    //   }
    // })

    // return 'ok'

    if(id !==0)

      fetch(`${process.env.REACT_APP_URL_CMS}/api/users/favorites/pois/${id}`, {
        method: 'POST',
        headers: {
          'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
          "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
        },
              
      }).then(() => {
        // console.log("Called addRemoveFavorite");
      })
      return 'Ok'
  }





}