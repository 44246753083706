
export const GoodPracticesEn = [{
  id: 1,
  link: '/good_practices/greece_makes_accessibility',
  image:'./img/GoodPractices/GoodPractices_1.jpg',
  title: 'Greece makes accessibility for all travelers priority',
  text: `Greece took one more step towards ensuring its destinations are fully accessible to residents and travelers after Tourism Minister Vassilis 
  Kikilias signed a protocol of cooperation with the National Confederation of Persons with Disabilities (ESAmeA).`
},
{
  id: 2,
  link: '/good_practices/acropolis_to_be_accessible',
  image:'./img/GoodPractices/GoodPractices_2.jpg',
  title: 'Acropolis to be accessible to visually impaired visitors as of June 2022',
  text: `The Acropolis in Athens and its monuments will become accessible to visually impaired individuals as of June 2022, Culture Minister Lina 
  Mendoni announced during a scientific conference held at the Peace and Friendship Stadium in Athens.`
},
{
  id: 3,
  link: '/good_practices/acropolis_gets_wheelchair_lift',
  image:'./img/GoodPractices/GoodPractices_3.jpg',
  title: 'Acropolis gets wheelchair lift, now accessible to disabled visitors',
  text: `Visitors with mobility issues will now have access to the Acropolis monuments in Athens thanks to a new lift and specially designed paths 
  facilitating wheelchair access.`
}

]






