import React, { useState, useEffect } from 'react'
import { SearchOutlined, MenuOutlined } from "@ant-design/icons"
import { Button, Select } from 'antd'
import { Link, NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classes from "./Navbar.module.css"
import { FilePdfOutlined } from '@ant-design/icons'

import { NavHashLink } from 'react-router-hash-link'
import CompassSvg from '../../img/svg/compass'


const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation()

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }


  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

    return (
      <div>
        {(toggleMenu || screenWidth > 1280) && (
          <ul className={ [classes.list, classes.items].join(" ")}>
            <NavLink to="/gsis" state={{ page:'gsis' }} onClick={toggleNav} className={({ isActive }) => isActive ? classes.bottomLine : '' }>GIS MAP</NavLink>
            <NavLink to="/project" state={{ page:'project' }} onClick={toggleNav} className={({ isActive }) => isActive ? classes.bottomLine : '' }>THE PROJECT</NavLink>
            <NavLink to="/partners" state={{ page: 'partners' }} onClick={toggleNav} className={({ isActive }) => isActive ? classes.bottomLine : '' }>PARTNERS</NavLink>
        
            <a href='./img/gis_altertrip_manual_portal.pdf' className={classes.linkA} target="_blank" >ONLINE HELP <FilePdfOutlined /></a>

            <span className={classes.searchButton} ><Button size="large" icon={<SearchOutlined />} style={{ color: '#275674'}}><span></span>Search</Button></span>
          </ul>
        )}
        <button onClick={toggleNav} className={classes.btn}><MenuOutlined /></button>
      </div>
    )

}
export default Navbar
