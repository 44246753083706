import React from "react"
import Props from './svgProps'
    
const MuseumsSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_3002" data-name="Path 3002" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#c7b299"/>
          <path id="Path_3003" data-name="Path 3003" d="M11.274,38.519h26.4c1.2,0,1.2,0,1.2-1.2s0-1.2-1.2-1.2h-26.4c-1.2,0-1.2,0-1.2,1.2S10.074,38.519,11.274,38.519Zm22.8-4.8h.6c1.2,0,1.2,0,1.2-1.2v-12c0-1.2,0-1.2-1.2-1.2h-.6c-1.2,0-1.2,0-1.2,1.2v12c0,1.2,0,1.2,1.2,1.2m-6.6,0h.6c1.2,0,1.2,0,1.2-1.2v-12c0-1.2,0-1.2-1.2-1.2h-.6c-1.2,0-1.2,0-1.2,1.2v12c0,1.2,0,1.2,1.2,1.2m-6.6,0h.6c1.2,0,1.2,0,1.2-1.2v-12c0-1.2,0-1.2-1.2-1.2h-.6c-1.2,0-1.2,0-1.2,1.2v12c0,1.2,0,1.2,1.2,1.2m-6.6,0h.6c1.2,0,1.2,0,1.2-1.2v-12c0-1.2,0-1.2-1.2-1.2h-.6c-1.2,0-1.2,0-1.2,1.2v12c0,1.2,0,1.2,1.2,1.2m10.2-18.6a1.8,1.8,0,1,1,1.8-1.8,1.805,1.805,0,0,1-1.8,1.8m-13.2,1.8h26.4c1.2,0,1.2,0,1.2-1.2v-.6c0-.6,0-.6-1.2-1.2l-13.2-5.4-13.2,5.4c-1.2.6-1.2.6-1.2,1.2v.6C10.074,16.919,10.074,16.919,11.274,16.919Z" fill="#fff"/>
        </g>
      </svg>
    )
}

export default MuseumsSvg  