import { useState } from "react"
import { useQuery } from '@tanstack/react-query'

import { axiosRegisterOrSignUp } from "./Service"
import { useGeolocated } from "react-geolocated";

// Redux
import { useDispatch } from 'react-redux'
import { Login }  from '../../redux/reducer/login'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'


// export const usePoints2 = () => {

//   const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
//     positionOptions: {
//         enableHighAccuracy: false,
//     },
//     userDecisionTimeout: 100,
//   });

//   return { coords }
// }

export const useUserRegister = (email: string, password: string) => {

  const [Message, setMessage] = useState<number>(); 


  const { refetch } = useQuery(['register'], () => axiosRegisterOrSignUp.postRegister(email, password), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {

      setMessage(data)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { Message, refetch }
}


export const useToken = (code: string) => {

  const [Token, setToken] = useState<string>(''); 
  const dispatch = useDispatch()

  const { isLoading, refetch } = useQuery(['token'], () => axiosRegisterOrSignUp.getToken(code), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {

      dispatch(Login(data.access_token))

      // console.log('Access_Token', data.access_token)
      // console.log('Refresh_Token', data.refresh_token)

      window.localStorage.setItem("access_token", data.access_token);
      window.localStorage.setItem("refresh_token", data.refresh_token);

      setToken(data.access_token)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, Token, refetch }
}


export const useRefreshTokenHook = () => {


  const { refetch: refetchRefreshTokenHook } = useQuery(['refreshToken'], () => axiosRegisterOrSignUp.refreshToken(), {

    refetchOnWindowFocus: false,
    enabled: false,

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { refetchRefreshTokenHook }
}

