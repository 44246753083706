import React, { useEffect, useRef, useState, useCallback, useMemo} from "react"
import { Col, Row } from 'antd'
import classes from "./styles.module.css"
import SvgPointer from "../../img/LiefletSvgPointers"


import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter'
// LEAFLET
import * as L from 'leaflet'
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, Tooltip, LayersControl, LayerGroup} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { PointsProps } from "../../services/props"
import point from "../../img/icon_generic.svg"
import i_am from "../../img/i_am.svg"
import {usePoints2 } from '../../hooks/useMap'

import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css"
import Routing from "./Routing"


let SVG_Point = new Icon({
  iconUrl: point,
  popupAnchor: [0, -10],
  iconSize: [40, 40]
})

let SVG_Point_I_AM = new Icon({
  iconUrl: i_am,
  popupAnchor: [0, -20],
  iconSize: [40,40]
})


interface Props{
  Points: any
  IdPoint: (e: number) => void
  sendTextToSpeech: (e:string) => void
  Tolat: number
  Tolon: number
  zoom: number
  hideLine: boolean
}

const LiefletComponent: React.FC<Props> = ({ Points, IdPoint, sendTextToSpeech, Tolat, Tolon, zoom, hideLine }:Props ) => {

  const markerRef = useRef<any>(null)
  const markerRefPopup = useRef<any>(null)
  const mapRef = useRef<any>(null);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

  const [minLon, setMinLon] = useState<string>('')
  const [maxLon, setMaxLon] = useState<string>('')

  const [iamLat, setIamLat] = useState<number>(0)
  const [iamLon, setIamLon] = useState<number>(0)
  const [text, setText] = useState(null)

// 41.367549613405
// 41.1456102
// 41.180042886588
  
  
  //23.427572250366
  //22.9014127
  //22.761134505272
const { coords } = usePoints2()
  
useEffect(() => {
    
  if (coords) {
    setIamLat(coords.latitude)
    setIamLon(coords?.longitude) 
  }

}, [coords])
  
  
useEffect(() => {
  if (zoom == 6 && coords) {
    const map = mapRef.current;
    // map.flyTo([40.635067606732896, 22.940877408867753], 12);
    map.flyTo([Points[0].lat.toString(), Points[0].lon.toString()], 9);
  }
}, [zoom])
  
  const eventHandlers = useMemo(() => ({
    dragend(e:any) {
      setIamLat(e.target.getLatLng().lat)
      setIamLon(e.target.getLatLng().lng)
    },
    
  }), [text])


  const onFlyTo = (lat: string, long: string) => {
    const map = mapRef.current;
    map.flyTo([lat, long], 14);
  }


  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
    return () => {
    //     const map = mapRef.current;
    // new SimpleMapScreenshoter().addTo(map)
          window.removeEventListener('resize', handleWindowSizeChange);
    }
    
  }, []);
  
  const isMobile = width <= 768;



  // useEffect(() => {
    
  //   const marker = markerRef.current;
  //   if (marker) {
  //     marker.openPopup();
  //   }
  // }, [markerRef.current])


  const MarkerIam = useCallback(() => {
    return<>
      <Marker
        eventHandlers={eventHandlers}
        icon={SVG_Point_I_AM}
        position={[iamLat, iamLon]}
        ref={markerRef}
        draggable={isMobile ? false : true}
      >
        <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent ><div className={classes.iamhere}>I am Here</div><br />{!isMobile && 'You can Drag me'}</Tooltip>
      </Marker>
    </>
  }, [iamLat, iamLon])


  const MarkersPois = useCallback(() => {
   
    return <>
      {
        Points.map((point: PointsProps, i: number, row: any) => {
          
          if (point.lon < maxLon) {
           
          }

          let textShort =  point.text ? `${point.text.substring(0, 160)}` : ' '
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))

          const icon = new Icon({
            iconUrl:
              point.category_id === 8 ? SvgPointer[0]
                : point.category_id === 11 ? SvgPointer[1]
                  : point.category_id === 13 ? SvgPointer[2]
                    : point.category_id === 14 ? SvgPointer[3]
                      : point.category_id === 17 ? SvgPointer[4]
                        : point.category_id === 18 ? SvgPointer[5] : SvgPointer[6],
                            
            popupAnchor: [0, -20],
            iconSize: [40, 40]
          });



          return (
            <Marker
              key={point.id}
              icon={icon}
              ref={markerRefPopup}
              position={[parseFloat(point.lat), parseFloat(point.lon)]}
              eventHandlers={{
                click: () => [IdPoint(point.id), onFlyTo(point.lat, point.lon), sendTextToSpeech(point.title)],
                mouseover: (e) => {
                  e.target.openPopup();
                  // console.log('data')
                },
                mouseout: (e) => {
                  setTimeout(() => {
                    e.target.closePopup();
                  }, 100);
                }
              }}
            >
              <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
                <div className={classes.Marker}>
                  <div className={classes.MarkerTitle}>Destination (POI) {alphabet[i]}:</div>
                  <div className={classes.MarkerDiv}>{point.title}</div>
                </div>
              </Tooltip>
              {/* <Popup>
                <div className={classes.Marker}>
                <div className={classes.MarkerTitle}>Destination (POI) {alphabet[i]}:</div>
                  {
                    point.images.length !== 0 &&
                    <img src={ point.images[0].url} alt={point.title} />
                  }
                  <div className={classes.MarkerDiv}>{point.title}</div>
                </div>
              </Popup> */}
            </Marker>
          )
        })
      }
    </>
  }, [Points])



  const RoutingMap = useCallback(() => {

    return(

      Points.slice(0, Points.length - 1).map((poi: any, index: number) => {

        if (Points.length !== 1)

          return (
            <div key={index}>
              <Routing
                IamLat={Points.length !== 2 ? index < Points.length - 1 ? Points[index].lat : Points[index - 2].lat : index < Points.length - 1 ? Points[index].lat : Points[index - 1].lat}
                IamLng={Points.length !== 2 ? index < Points.length - 1 ? Points[index].lon : Points[index - 2].lon : index < Points.length - 1 ? Points[index].lon : Points[index - 1].lon}
                Tolat={Points.length !== 2 ? index < Points.length - 1 ? Points[index + 1].lat : Points[index - 1].lat : index < Points.length - 1 ? Points[index + 1].lat : Points[index].lat}
                Tolon={Points.length !== 2 ? index < Points.length - 1 ? Points[index + 1].lon : Points[index - 1].lon : index < Points.length - 1 ? Points[index + 1].lon : Points[index].lon}
                index={Points.length <= 2 ? 0 : index}
              />
            </div>
          )
      })
    )

  }, [Points])







  if (Points.length == 0 ) {
    return null
  }


  return (
    <>
      <Row justify="center" id='der'>
        <Col span={24} >

          <MapContainer
            key={JSON.stringify([40.63818870604024, 22.937615317285847])} // To center every time
            // center={[Points[0].lat, Points[0].lon]}
            // center={[0,0]}
            zoom={zoom}
            tap={false}
            style={{ height: '700px', zIndex: '0', width: 'auto' }}
            zoomControl={false}
            scrollWheelZoom={true}
            ref={mapRef}
           
          >
        
          <ScaleControl position="bottomright" metric imperial={false} />
            {/* <MarkerIam /> */}
          

            { !hideLine &&
            
              <RoutingMap />
              // Points.slice(0, Points.length - 1).map((poi: any, index: number) => {
              //   // console.log('Points', Points)
              
              //   if (Points.length !== 1)
              
              //     return (
              //       <div >
              //         <Routing
              //           IamLat={Points.length !== 2 ? index < Points.length - 1 ? Points[index].lat : Points[index - 2].lat : index < Points.length - 1 ? Points[index].lat : Points[index - 1].lat}
              //           IamLng={Points.length !== 2 ? index < Points.length - 1 ? Points[index].lon : Points[index - 2].lon : index < Points.length - 1 ? Points[index].lon : Points[index - 1].lon}
              //           Tolat={Points.length !== 2 ? index < Points.length - 1 ? Points[index + 1].lat : Points[index - 1].lat : index < Points.length - 1 ? Points[index + 1].lat : Points[index].lat}
              //           Tolon={Points.length !== 2 ? index < Points.length - 1 ? Points[index + 1].lon : Points[index - 1].lon : index < Points.length - 1 ? Points[index + 1].lon : Points[index].lon}
              //           index={Points.length <= 2 ? 0 : index}
              //         />
              //       </div>
              //     )
              // })
            
            }
<MarkersPois />
     

            <LayersControl position="topright">

                <LayersControl.BaseLayer  name='Satellite'>
                  <LayerGroup >
                    <TileLayer maxZoom={19} url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
                  </LayerGroup>
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer checked name='Version 2'>
                  <LayerGroup >
                    <TileLayer maxZoom={19} url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
                  </LayerGroup>
                </LayersControl.BaseLayer>

            </LayersControl>

          </MapContainer>

        </Col>
      </Row>


      
    </>
  )
}

export default LiefletComponent