import React from "react"
import Props from './svgProps'
    
const LimitedMobilitySvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_2963" data-name="Path 2963" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="blue"/>
          <path id="Path_2964" data-name="Path 2964" d="M20.989,7.642A2.563,2.563,0,1,1,18.426,10.2a2.562,2.562,0,0,1,2.563-2.563" fill="#fff"/>
          <path id="Path_2965" data-name="Path 2965" d="M22.6,37.194a9.733,9.733,0,0,1-9.722-9.722A10.122,10.122,0,0,1,17.779,19l.247,2.371c-2.279,1.984-3.114,3.609-3.114,6.1a7.84,7.84,0,0,0,7.69,7.691,7.521,7.521,0,0,0,7.822-6.486L31.808,31A9.651,9.651,0,0,1,22.6,37.194" fill="#fff"/>
          <path id="Path_2966" data-name="Path 2966" d="M38.77,30.625l-4.251,2.657-4.186-7.136s-8.443.1-8.529.1a2.562,2.562,0,0,1-2.563-2.563L18.7,16.238a2.51,2.51,0,0,1-.025-.337,2.563,2.563,0,0,1,2.563-2.563A2.434,2.434,0,0,1,23.641,15.9l.182,2.494c.951,0,4.782,0,4.832,0,.72.05,1.29.38,1.293,1.267,0,.7-.738,1.113-1.458,1.062l-4.5,0,.11,1.511c2.527-.038,6.93-.089,7.307.277,1.171,1.133,4.5,7.272,4.5,7.272l1.751-1.019a1.072,1.072,0,0,1,.456-.1,1.085,1.085,0,0,1,.648,1.957" fill="#fff"/>
        </g>
      </svg>
    )
}

export default LimitedMobilitySvg  