import React, { useState, useEffect }  from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { useLocation } from "react-router-dom"
import { useToken } from '../../components/Login/useService'
  
const LoginPage: React.FC = () => {

  const [codeParam, setCodeParam] = useState<any>(null)

  const { isLoading, Token, refetch } = useToken(codeParam)

  useEffect(() => {
    const codeParam = new URLSearchParams(window.location.search).get('code')
    setCodeParam(codeParam)
    
    if (codeParam)
      window.localStorage.setItem("codeParam", codeParam);
  }, [])


  useEffect(() => {
    if (codeParam !== null) {
      refetch()
    }
  }, [codeParam])


  console.log('MyToken is: ', Token)
  console.log('MyToken From LocalStorage is: ', window.localStorage.getItem("access_token"))

  return (
    <>

      <div className={classes.backgroundGrey}>
        <div className={classes.container}>
          <Row justify="center">
            <Col xs={{span:22}} lg={{span : 20}} >
              
                {/* <div className={classes.titleGp} dangerouslySetInnerHTML={{ __html: data.header }} ></div> */}
              <div className={classes.text}>{ Token }</div>
             
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LoginPage