import React from 'react'
import { Row, Col, Carousel } from 'antd'
import classes from "./css/GoodPractices.module.css"
import { Link } from 'react-router-dom'
import { GoodPracticesEn } from './GoodPracticesData'
import  GoodPracticesComponent from '../../../components/PageComponents/GoodPractices'
import { useGoodPractices } from '../../../hooks/useMap'
import { SampleNextArrow, SamplePrevArrow } from '../../../components/ArrowsForCarousel/Categories'


interface Events{
  id: string
  link: string
  image: string
  title: string
  text: string
}

const GoodPractices: React.FC = () => {


 // const { isLoading, isFetching, Data } = useGoodPractices()

  const settings = {
    dots: false,
    arrows:true,
    infinite: true,
    adaptiveHeight: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }  
  return (
    <>
      <div className={classes.containerBig}>
        <div className={classes.container}>
          
          <Row justify="center" >
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className={classes.div}>
                <span className={classes.btn}><Link to='good_practices' style={{color: '#00398b'}}>Good Practices</Link></span>
              </div>
            </Col>
          </Row>

          {/* <Row justify="center" > */}
            <div className={classes.sliderContainer}>
              <Carousel autoplay  {...settings}>
                {GoodPracticesEn.map((data:any) => {
                  return (
                  // <Col xs={24} sm={24} md={12} lg={12} key={data.id}>
                    <div key={data.id} style={{width: '700px'}}>
                      <GoodPracticesComponent
                        // image={data.image.url}
                        image={data.image}
                        title = { data.title}
                        text= { data.text }
                        link = {data.link} 
                      />
                      {/* <p style={{color: 'black'}}>kjhkhjk</p> */}
                   </div>
                  //  </Col>
                  )
                })}
              </Carousel>
          </div>
          
          {/* </Row> */}
        </div>
      </div>
    </>
  )
}

export default GoodPractices