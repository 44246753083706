import React from "react"
import Props from './svgProps'
    
const VisualSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path data-name="Path 2967" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#ff3b00"/>
          <path data-name="Path 2968" d="M24.871,27.974a5.411,5.411,0,1,0-3.488-9.545l3.09,3.989-4.79-1.388a5.41,5.41,0,0,0,5.188,6.944Z" fill="#fff"/>
          <path data-name="Path 2969" d="M44.3,21.892a24.933,24.933,0,0,0-20.1-10.54A24.926,24.926,0,0,0,4.675,21.133l-.707.894.676.918a24.939,24.939,0,0,0,20.1,10.539A24.925,24.925,0,0,0,44.271,23.7l.708-.893Zm-19.54,8.183a7.508,7.508,0,0,1-7.2-9.66l-1.723-.5a9.275,9.275,0,0,0,2.836,9.671,22.55,22.55,0,0,1-10.918-7.5A22.443,22.443,0,0,1,18.8,15.063l.155.235,1.1,1.416a7.51,7.51,0,1,1,4.707,13.361Zm6.119-.519a9.278,9.278,0,0,0-.209-14.174,22.586,22.586,0,0,1,10.514,7.363,22.528,22.528,0,0,1-10.305,6.81Z" fill="#fff"/>
        </g>
      </svg>
    )
}

export default VisualSvg  