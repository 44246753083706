import React from "react"
import { Checkbox, Col, Row, Collapse, Tooltip } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import classes from "./styles.module.css"

import VisualSvg from '../../img/svg_pointers/VisualSvg'
import LimitedMobilitySvg from '../../img/svg_pointers/LimitedMobilitySvg'
import HearingSvg from '../../img/svg_pointers/HearingSvg'

import CheckBoxMapComponent from './checkBoxGroupMap'





// Redux
import { useDispatch } from 'react-redux'
// import { History, Environment, Municipality }  from '../../redux/mapPage/category'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'



interface Props{
  checkedId?: number;
  array: any;
  FacilityArray: any;
  collapse: string
  CheckBoxChecked?: (checkbox: CheckboxValueType[]) => void
}

const CheckBoxComponent: React.FC<Props> = ({  array, FacilityArray, collapse, CheckBoxChecked }) => {


  // const ReduxHistory: any[] = useSelector((state: RootState) => state.Categories.History)
  // const ReduxEnvironment: any[] = useSelector((state: RootState) => state.Categories.Environment)
  // const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Categories.Municipality)
  
  const basket: any[] = useSelector((state: RootState) => state.Basket.Basket)

  const onChange = (list: CheckboxValueType[]) => {

    console.log(list)

    // type === 'history' ? dispatch(History(list)) : type === 'environment' ? dispatch(Environment(list)) : dispatch(Municipality(list))
  }


  return (
    <>

    <Checkbox.Group
      style={{ width: '100%', color: 'white' }}
      onChange={CheckBoxChecked}
    // defaultValue={ FacilityArray }
    >

      <Row>
        <p className={classes.checkboxPadding}>
          <span className={classes.checkboxItem}>General</span>
        </p>
        <CheckBoxMapComponent array={array[0]} collapse={collapse} />
      </Row>
      <hr style={{ width: '230px' }} />

      <Row>
        <p className={classes.checkboxPadding}>
          <span className={classes.checkBoxSvg}><VisualSvg width={46} height={46} /></span>
          <span className={classes.checkboxItem}>Visual impairment</span>
        </p>
        <CheckBoxMapComponent array={array[1]} collapse={collapse} />
      </Row>
      <hr style={{ width: '230px' }} />

      <Row>
        <p className={classes.checkboxPadding}>
          <span className={classes.checkBoxSvg}><HearingSvg width={46} height={46} /></span>
          <span className={classes.checkboxItem}>Hearing impairment</span>
        </p>
        <CheckBoxMapComponent array={array[2]} collapse={collapse} />
      </Row>
      <hr style={{ width: '230px' }} />
  
      <Row>
        <p className={classes.checkboxPadding}>
          <span className={classes.checkBoxSvg}><LimitedMobilitySvg width={46} height={46} /></span>
          <span className={classes.checkboxItem}>Physical impairment</span>
        </p>
        <CheckBoxMapComponent array={array[3]} collapse={collapse} />
      </Row>

    </Checkbox.Group>
       
  </>
  )
}

export default CheckBoxComponent