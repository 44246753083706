import React from 'react'
import { Row, Col, Carousel, Button } from 'antd'
import classes from "./css/Legislation.module.css"
import { Link } from 'react-router-dom'
import { SampleNextArrow, SamplePrevArrow } from '../../../components/ArrowsForCarousel/Categories'
import RightsAndLegislationComponent from '../../../components/PageComponents/RightsAndLegislation'
import { useRightsAndLegislations } from '../../../hooks/useMap'
import { ThreeDataEn } from './ThreeD'

interface Events{
  id: number
  link: string
  title: string
  text: string
}


const Legislation: React.FC = () => {


 // const { isLoading, isFetching, Data } = useRightsAndLegislations()

  const settings = {
    dots: false,
    arrows:true,
    infinite: true,
    adaptiveHeight: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }  

  return (
    <>
      <div className={classes.containerBig}>
        <div className={classes.container}>
          
          <Row justify="center" >
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className={classes.div}>
                <span className={classes.btn}><Link to='rights_and_legislation' style={{color: '#00398b'}}>Rights and legislation</Link></span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className={classes.sliderContainerEnd}>ALTER TRIP</div>
            </Col>
          </Row>

          <Row justify="center" >
            <Col span={24}>
              <div className={classes.sliderContainer}>
                <Carousel autoplay {...settings} >

                  {ThreeDataEn.map((data) => {
                    return (
                      <div key={data.id}>
                        <RightsAndLegislationComponent
                          title = { data.title}
                          text= { data.text }
                          link = {data.link} 
                        />    
                      </div>
                    )
                  })}
  
                </Carousel>
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </>
  )
}

export default Legislation