import React, { useEffect, useState } from "react"
import { Avatar, Badge, Button, Dropdown, List, Menu } from 'antd'
import classes from "./styles.module.css"
import { PointsProps } from '../../services/props'
import { BellOutlined, DeleteOutlined } from '@ant-design/icons'
import ReactDragListView from "react-drag-listview"
import { Link, useLocation  } from 'react-router-dom'
import axios from 'axios'


//    Redux
import type { RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { deleteItemFromBasket, deleteAllFromBasket, changeBasket } from '../../redux/reducer/basket'
import { AddToGsisPlannedSlice, deleteAllFromGsisPlanned }  from '../../redux/reducer/gsisPlanned'
import ModalComponent from '../../pages/Gsis/Modal'
import useSpeech from "use-speech18"

interface BasketItemProps{
  item?: any
}


const BasketComponent: React.FC<BasketItemProps> = ({ item }: BasketItemProps) => {

  const dispatch = useDispatch()
  const location = useLocation()

  const [visible, setVisible] = useState(false);
  const basket: any[] = useSelector((state: RootState) => state.Basket.Basket)
  const checkedFilters: any[] = useSelector((state: RootState) => state.GsisChecked.GsisChecked)
  

  const [MyBasket, setMyBasket] = useState<PointsProps[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [itemPoi, setItemPoi] = useState<any>()

  // For Drag and Drop
  const onDragEnd = (fromIndex: any, toIndex: any) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    setMyBasket((prev) => {
      const items = [...prev];
      const item = items.splice(fromIndex, 1)[0];
      items.splice(toIndex, 0, item);
      dispatch(changeBasket(items))
      return items;
    })
  };

  //////////////////////////////////////////      Speech Voices     ///////////////////////////////////////////
  const { Speak, speak_utils } = useSpeech()
  const [isSpeech, setSpeech] = useState<boolean>(true)


  const textToSpeech = (title: string) => { isSpeech && Speak(title) }

  function getSelectedText() {
    isSpeech && Speak(window?.getSelection()?.toString() || '')
  }

  useEffect(()=> {
    // console.log("array of avalaible voices", speak_utils.Voices)
    speak_utils.setVoice(speak_utils.Voices[2].name)
    // console.log(speak_utils.selectedVoice, "currently selected voice")   
  }, [speak_utils.Voices])


  const onChangeSpeech = (checked: boolean) => {
    setSpeech(checked)
  };

 //////////////////////////////////////////      end Speech Voices     ///////////////////////////////////////////

 
  useEffect(() => {
    setMyBasket(basket)
  }, [basket])

  const deleteItemFromBasketFunc = (id: number) => {

    // setMyBasket(MyBasket.filter((item) => item.id !== id))
    dispatch(deleteItemFromBasket(id))
  }

  const deleteAllFromBasketFunc = () => dispatch(deleteAllFromBasket())
  
  

  const getNotificationBody = (list: PointsProps[]) => {
    return list.length > 0 ?

      <ReactDragListView
        onDragEnd={onDragEnd}
        nodeSelector='.ant-list-item'>

        <List
          size="small"
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item: PointsProps) => {

            return (

              <List.Item>

                <div className={classes.dFlex} onClick={() => [setItemPoi(item), setOpenModal(true), setVisible(false) ] }>
                {/* <div className={classes.dFlex} onClick={() => console.log('fds') }> */}
                  <Avatar
                    icon={item.images.length >= 1
                      ? <img src={item.images[0].url} alt='Title image' />
                      : <img src='./img/No-image-available.png' alt='Title image' />}
                  >
                  </Avatar>
                  <div>
                    <div className={classes.avatarTitle}>{item.category}</div>
                    <div className={classes.avatarSubtitle}>{item.title}</div>
                  </div>
                </div >
                <div className="mr-3" >
                  <DeleteOutlined
                    className={classes.avatarDelete}
                    onClick={() => deleteItemFromBasketFunc(item.id)}
                  />
                </div>

              </List.Item>
            )
          }}
        />

      </ReactDragListView>
      :
      <div className={classes.emptyNotification}>
        <img src="./img/No-image-available.png" alt="empty" />
        <p className="mt-3">You have viewed all notifications</p>
      </div>
  }


  const CreateBasketForGsisPlanner = () => {

    let str = ''

    dispatch(deleteAllFromGsisPlanned())

    basket.map((item: any) => {
      
      return str+= item.id + ','

    })

    // console.log('checkedFilters', checkedFilters[checkedFilters.length -1])
    // console.log('str', str.slice(0, -1))
    // console.log(str.slice(0, -1))

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API}/api/pois/suggestions?pois=${str.slice(0, -1)}&facillities=${checkedFilters[checkedFilters.length -1]}`,
    }).then(({ data }) => {

      dispatch(AddToGsisPlannedSlice(data))

    }).catch(err => console.log("err", err))
    
  }


  // const clear = () => {
  //   // dispatch(clearNotification())
  //   setVisible(false);
  // }

  const handleVisibleChange = (flag:any) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className={[classes.navDropdown, classes.navNotification].join(" ")}>
      <div className={[classes.navNotificationHeader, classes.dFlex, classes.justifyContentBetween, classes.alignItemsCenter].join(" ")} >
      <h4 className={classes.mb0}>Your Routes</h4>
        <Button className={classes.textPrimary} type="text" onClick={deleteAllFromBasketFunc} size="small">Clear</Button>
      </div>
        <div className={classes.navNotificationBody}>
        {getNotificationBody(MyBasket)}
      </div>
      {
        MyBasket.length > 0 ? location.pathname !== '/gsis_planned' ?
          
          <div className={ classes.navNotificationFooter } onClick={()=> CreateBasketForGsisPlanner()}>
            <Link to='/gsis_planned'><Button size="small">Export Routes</Button></Link>
          </div>
        : null
        : null
      }
    </div>
  )


  return (
    <>
      {MyBasket.length >= 1 ?
        <>
        <Dropdown
          placement="bottomRight"
          overlay={notificationList}
          onVisibleChange={handleVisibleChange}
          visible={visible}
          trigger={['click']}
        >
          <Menu mode="horizontal">
            <Menu.Item key="notification">
              <Badge count={MyBasket.length}>
                <BellOutlined className="nav-icon mx-auto" type="bell" />
              </Badge>
            </Menu.Item>
          </Menu>
        </Dropdown>

        </>
        :
        null
      }
      <ModalComponent
        item={itemPoi}
        openModalBoolean={openModal}
        setOpenModalBoolean={() => setOpenModal(false)}
        getSelectedText={ getSelectedText }
        />
    </>
  )
}

export default BasketComponent