import React from "react"
import Props from './svgProps'
    
const VariousSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_3008" data-name="Path 3008" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#333"/>
          <path id="Path_3009" data-name="Path 3009" d="M10.114,19.1V36.786a1.449,1.449,0,0,0,.44,1.066,1.386,1.386,0,0,0,1.7.141L18.22,34.06l5.916,3.915a1.364,1.364,0,0,0,.2.113l.031.016.12.046.117.037.1.021a1.451,1.451,0,0,0,.218.022h.019a1.377,1.377,0,0,0,.255-.025h.046a1.338,1.338,0,0,0,.221-.067,1.363,1.363,0,0,0,.224-.117l6.742-4.462a1.38,1.38,0,0,0,.615-1.155V26.783a1.383,1.383,0,0,0-2.766,0V31.66l-3.958,2.627V19.095A1.379,1.379,0,0,0,25.7,17.94l-6.711-4.425a1.333,1.333,0,0,0-.2-.111l-.068-.024a1.254,1.254,0,0,0-.168-.052l-.074-.016a1.4,1.4,0,0,0-.492,0l-.074.016a1.267,1.267,0,0,0-.169.052l-.067.024a1.287,1.287,0,0,0-.2.111l-6.739,4.428A1.383,1.383,0,0,0,10.114,19.1ZM19.6,17.233l3.949,2.612V34.287L19.6,31.66ZM12.88,19.845l3.958-2.612V31.675L12.88,34.287Z" fill="#fff"/>
          <path id="Path_3010" data-name="Path 3010" d="M38.833,14.007a5.789,5.789,0,0,0-5.786-5.778h-.375a5.838,5.838,0,0,0-5.411,5.84c.037,3.876,3.257,7.524,4.876,9.116a1.3,1.3,0,0,0,1.822,0C35.588,21.6,38.833,17.918,38.833,14.007Zm-8.1,0a2.318,2.318,0,1,1,.679,1.634,2.317,2.317,0,0,1-.679-1.634" fill="#fff"/>
        </g>
      </svg>
    )
}

export default VariousSvg  