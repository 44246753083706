import { configureStore } from '@reduxjs/toolkit'

import Login from './reducer/login'
import Basket from './reducer/basket'
import GsisPlanned from './reducer/gsisPlanned'
import GsisChecked from './reducer/gsisChecked'

export const store = configureStore({
  reducer: {
    Logged: Login,
    Basket: Basket,
    GsisChecked: GsisChecked,
    GsisPlanned: GsisPlanned,
  },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch