import React from "react"
import Props from './svgProps'
    
const ReligiousSitesSvg = ({width, height}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.947 62">
        <g data-name="Group 950" clipPath="url(#clipPath)">
          <path id="Path_3004" data-name="Path 3004" d="M24.474,0A24.474,24.474,0,0,0,0,24.474C0,37.99,24.474,62,24.474,62S48.948,37.99,48.948,24.474A24.474,24.474,0,0,0,24.474,0" fill="#d53f31"/>
          <path id="Path_3005" data-name="Path 3005" d="M25.8,21.709h-.067v.031H23.208v-.031h-.063V18.977a1.327,1.327,0,0,1,2.654,0ZM24.475,8.774l-5.053,9.532v9.036l5.029-3.082,5.07,3.082V18.306Z" fill="#fff"/>
          <path id="Path_3006" data-name="Path 3006" d="M33.942,30.654v10.34h-7.2V35.683a2.269,2.269,0,1,0-4.539,0v5.311h-7.2V30.65l9.464-5.9Z" fill="#fff"/>
          <path id="Path_3007" data-name="Path 3007" d="M23.841,5.123V6.381H22.577V7.639h1.264v2.515H25.1V7.639h1.262V6.381H25.1V5.123Z" fill="#fff"/>
        </g>
      </svg>
    )
}

export default ReligiousSitesSvg