import React from "react"
import { Row, Col  } from 'antd'
import classes from './styles.module.css'
import { ThreeDataEn } from '../Home/components/ThreeD'
import { GoodPracticesEn } from '../Home/components/GoodPracticesData'
import GoodPracticesComponent from '../../components/PageComponents/GoodPractices'
import { useGoodPractices } from '../../hooks/useMap'

interface Props{
  id: string
  image: string
  title: string
  text: string
  link: string
}

const GoodPracticesPage: React.FC = () => {



  const { isLoading, isFetching, Data } = useGoodPractices()


  return (

    <>
      <div className={ classes.backgroundImage }>
        <div className={classes.container}>
          <div className={classes.title}>Good Practices</div>
        </div>
        <div className={classes.container}>
          <Row justify="center" >
            {Data.map((data: Props) => {
              return (
                <Col xs={24} sm={24} md={12} lg={12} key={data.id}>

                  <GoodPracticesComponent
                    image={data.image}
                    title={data.title}
                    text={data.text}
                    link={data.id}
                  />

                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </>
  )
}

export default GoodPracticesPage